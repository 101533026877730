import { useEffect, useState } from "react";
import { getItems, getResources, registrarEvento, getEventos, getFacturasCafc,
    sendFormCafc } from "./functions";
import dayjs from "dayjs";
import { App } from "antd";

const useContingencia = () => {
    const { notification, modal } = App.useApp();
    const [items, setItems] = useState([]);
    const [resources, setResources] = useState({
        venta_puntos: [],
        eventos_significativos: []
    });
    const [data, setData] = useState({
        cod_evento: "",
        tienda_pv_id: "",
        date_start: dayjs().subtract(5 ,'days').format('YYYY-MM-DD'),
        date_end: dayjs().format('YYYY-MM-DD'),
    });
    const [loading, setLoading] = useState(false);
    const [eventos, setEventos] = useState([]);
    const [loadingEventos, setLoadingEventos] = useState(false);

    const fillVentas = async () => {
        try {
            setLoading(true);
            let res = await getItems(data);
            setItems(res.data.items);
        } catch (error) { }
        finally { setLoading(false); }
    }

    const fillResources = async () => {
        let res = await getResources();
        let main_pv = res.data.venta_puntos.find(pv => pv.nombre === "CASA MATRIZ");
        setData(prev => ({...prev, tienda_pv_id: main_pv.id}));
        setResources(prev => ({
            ...prev,
            venta_puntos: res.data.venta_puntos,
            eventos_significativos: res.data.eventos_significativos
        }));

        setEventosFilter(prev => ({...prev, tienda_pv_id: main_pv.id}));
    }

    const setParams = (key, data) => {
        setData(prev => ({ ...prev, [key]: data }));
    }

    const [loadingRegEvento, setLoadingRegEvento] = useState(false);

    const regEvento = async (data) => {
        try {
            setLoadingRegEvento(true);
            let res = await registrarEvento(data);
            notification.success({
                message: "¡Evento registrado!",
            });
        } catch (error) { console.log(res); }
        finally { setLoadingRegEvento(false); }
    }

    const [eventosFilter, setEventosFilter] = useState({
        tienda_pv_id : (resources.venta_puntos.length > 0)? resources.venta_puntos[0].id: 0,
        date_start: dayjs().subtract(5, 'days').format('YYYY-MM-DD'),
        date_end: dayjs().format('YYYY-MM-DD'),
    });

    const fillEventos = async () => {
        try {
            setLoadingEventos(true);
            let res = await getEventos({
                ...eventosFilter,
            });
            setEventos(res.data);
        } catch (error) { }
        finally { setLoadingEventos(false); }
    }

    const setFilterParam = (key, value) => {
        setEventosFilter(prev => ({...prev, [key]: value}));
    }

    /* Modal Facturas CAFC */

    const [modalVentasCafcVisible, setModalVentasCafcVisible] = useState(false);
    const [ventasCafcLoading, setVentasCafcLoading] = useState(false);
    const [ventasCafc, setVentasCafc] = useState([]);
    const [initialCafcValues, setInitialCafcValues] = useState({
        contingencia_id: 0,
        cafc: ""
    });
    const [submitCafcLoading, setSubmitCafcLoading] = useState(false);

    const fillVentasCafc = async (contingencia_id) => {
        try {
            setVentasCafcLoading(true);
            let res = await getFacturasCafc(contingencia_id);
            setVentasCafc(res.data);
            setInitialCafcValues(prev => ({...prev, contingencia_id: contingencia_id}));
        } catch (error) { }
        finally { setVentasCafcLoading(false); }
    }

    const showModalVentasCafc = () => {
        setModalVentasCafcVisible(true);
    }

    const hideModalVentasCafc = () => {
        setVentasCafc([]);
        setModalVentasCafcVisible(false);
    }

    const submitCafc = async (values) => {
        try {
            setSubmitCafcLoading(true);
            await sendFormCafc(values);

            notification.success({
                message: "Envío Satisfactorio!"
            });
        } catch (error) {
            let {codigoEstado, response} = error.response.data;

            modal.error({
                content: (
                    <div>
                        <b>Error: </b>{codigoEstado}<br/>
                        <p>
                            { JSON.stringify(response) }
                        </p>
                    </div>
                )
            });

        } finally { setSubmitCafcLoading(false); }
    }
    /* End Modal Facturas CAFC */

    return {
        items,
        data,
        fillVentas,
        setParams,
        fillResources,
        resources,
        loading,
        loadingRegEvento,
        regEvento,
        eventos,
        fillEventos,
        loadingEventos,
        setFilterParam,
        eventosFilter,

        showModalVentasCafc,
        hideModalVentasCafc,
        modalVentasCafcVisible,
        ventasCafcLoading,
        ventasCafc,
        fillVentasCafc,
        initialCafcValues,
        submitCafc,
        submitCafcLoading
    };

}

export default useContingencia;

import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState, useContext, useId, useRef } from "react";
import * as Yup from "yup";
import { Select, Spin, Modal } from 'antd';
import FormError from "../../common/FormError";
import { EditableContext } from "../../common/EditableContextProvider";
import VentasProductos from "./VentasProductos";
import VentaItemsComponent from "./VentaItemsComponent";
import AddItemModal from "./modals/AddItemModal";
import { sprintf } from "sprintf-js";
import dayjs from "dayjs";
import { uniqueId } from "lodash";
import { ModalPagos } from "./modals/Pagos";
import { IMaskInput, IMaxInput } from "react-imask";

const FormComponent = ({ errors }) => {
    const {
        editItem, onFormAction, resources, searchNit, puntosVenta, validateNit, pagos
    } = useContext(EditableContext);
    const [initialValues, setInitialvalues] = useState(editItem);
    const [loading, setLoading] = useState(false);
    const [invalidNit, setInvalidNit] = useState(false);
    const formikRef = useRef();

    useEffect(() => {
        setInitialvalues(editItem);
    }, [editItem]);

    const after_submit = () => {
        formikRef.current.resetForm();
    }

    const handleSubmit = async (values, formik) => {

        if(values.cliente.cod_tipo_doc === 5) {
            let especialNits = ['99001','99002'];
            if(especialNits.includes(values.cliente.nodoc)) {
                pagos.showPagosModal({...values, nit_exception: true});
                return;
            }

            let isValid = false;

            try {
                setLoading(true);
                isValid = await validateNit(values.cliente.nodoc);
            } catch (error) { }
            finally { setLoading(false); }

            if(!isValid) {
                Modal.confirm({
                    title: 'Confirmar',
                    content: 'El Nit es inválido. ¿Continuar?',
                    onOk: () => {
                        pagos.showPagosModal({...values, nit_exception: true});
                    }
                })
            } else { pagos.showPagosModal(values); }
        } else { pagos.showPagosModal(values); }
    }

    const validateSchema = Yup.object({
        total: Yup.number()
            .moreThan(0, 'El total debe ser mayor a 0.')
            .required('El campo es obligario.'),
        tienda_pv_id: Yup.number()
            .required('El campo es obligario.'),
        cliente: Yup.object({
            nodoc: Yup.string()
                .required('El campo es obligario.'),
            razon_social: Yup.string()
                .required('El campo es obligario.'),
            cod_tipo_doc: Yup.number()
                .required('El campo es obligario.'),
        })
    });

    const [ventaItem, setVentaItem] = useState({
        index: 0,
        producto: null,
        cantidad: 1,
        precio_u: 0,
        subtotal: 0
    });

    const [showAddItem, setShowAddItem] = useState(false);

    const handleSelProduct = (producto, formik) => {
        let item_id = uniqueId();

        let descuento = 0;
        let subtotal = 0;
        if(producto.descuento) {
            let now = dayjs().format('YYYY-MM-DD');
            descuento = (now >= producto.descuento.fecha_inicio && now <= producto.descuento.fecha_fin)?
                producto.descuento.monto : 0;

            subtotal = Math.max(0, producto.precio - descuento);
        } else {
            descuento = 0;
            subtotal = Math.abs(producto.precio);
        }

        setVentaItem(prev => {
            return {
                ...prev,
                index: item_id,
                producto: producto,
                precio_u: producto.precio,
                descuento_pu: descuento,
                descuento_total: descuento,
                subtotal: subtotal
            };
        });
        setShowAddItem(true);
    }

    const hideAddItemModel = () => {
        setShowAddItem(false);
    }

    const handle_additem = (values, formik) => {
        let vitems = [...formik.values.items, values];
        formik.setFieldValue('items', vitems);
        hideAddItemModel();
        setTotalFormik(vitems, formik);
    }

    const removeVentaItem = (index, formik) => {
        let vitems = formik.values.items.filter(p => p.index !== index);
        formik.setFieldValue('items', vitems);
        setTotalFormik(vitems, formik);
    }

    const setTotalFormik = (vitems, formik) => {
        const total = vitems.reduce((t, item) => t + parseFloat(item.subtotal), 0);
        formik.setFieldValue('total', total);
    }

    const onChangeCliente = (e, formik) => {
        let { name, value } = e.target;
        formik.setFieldValue("cliente", {
            ...formik.values.cliente,
            [name]: value
        });
    }

    const handleBuscarCliente = async (e, formik) => {
        await searchNit({
            nodoc: formik.values.cliente.nodoc,
            complemento: formik.values.cliente.complemento
        }, formik);
    }

    const handleKeyUpNodoc = async (e, formik) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            handleBuscarCliente(e, formik);
        }
    }

    const setFormikRef = (formik) => {
        formikRef.current = formik;
    }

    return (
        <div>
            <div>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validateSchema}
                    enableReinitialize={true}
                >
                    {
                        (formik) => (
                            <Form disabled={loading}
                                onKeyDown={(e) => {
                                    if(e.key === 'Enter' || e.keyCode === 13) {
                                        e.preventDefault();
                                        return false;
                                    }
                                }}
                            >
                                { setFormikRef(formik) }
                                <div className="py-2">
                                    <div className="">
                                        <span>Fecha: </span>
                                        <span className="fw-bold ms-1">{dayjs(formik.values.fecha).format('DD/MM/YYYY')}</span>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    {/* <div className="row mb-1">
                                        <div className="col-md-2"></div>
                                        <div className="col-md">
                                            <span className="me-2">Crear Factura:</span>
                                            <button className="btn btn-sm btn-danger me-2 py-0 px-2"
                                                type="button"
                                                onClick={async (e) => { await searchNit({
                                                    nodoc: "99002",
                                                    complemento: null
                                                }) }}
                                            >
                                                Sin Nombre
                                            </button>
                                            <button className="btn btn-sm btn-secondary me-2 py-0 px-2"
                                                type="button"
                                                onClick={async (e) => { await searchNit({
                                                    nodoc: "99001",
                                                    complemento: null
                                                }) }}
                                            >
                                                ENTIDAD (Consulado, embajada, etc)
                                            </button>
                                        </div>
                                        <div className="col-md"></div>
                                    </div> */}
                                    <div className="row mb-2">
                                        <div className="col-md-3">
                                            <label>Punto de Venta:</label>
                                            <Select
                                                className="w-100"
                                                options={puntosVenta.map(pv => ({ label: pv.nombre, value: pv.id }))}
                                                value={formik.values.tienda_pv_id}
                                                onChange={(value) => formik.setFieldValue('tienda_pv_id', value)}
                                            ></Select>
                                            <ErrorMessage name="tienda_pv_id" component={FormError} />
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-2">
                                                <div className="col-md">
                                                    <div className="row">
                                                        <div className="col-md">
                                                            <label>NIT/CI</label>
                                                            <input type="text" className="form-control form-control-sm"
                                                                name="nodoc"
                                                                value={formik.values.cliente.nodoc}
                                                                onChange={(e) => onChangeCliente(e, formik)}
                                                                onKeyUp={(e) => handleKeyUpNodoc(e, formik)}
                                                                onKeyDown={(e) => { (e.key === 'Enter') && e.preventDefault() }}
                                                            />
                                                            <ErrorMessage name="nit_exception"
                                                                render={(msg) => (
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault"
                                                                            onChange={(e) => formik.setFieldValue('nit_exception', true)}
                                                                            value={formik.values.nit_exception}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                            Nit Invalido
                                                                        </label>
                                                                    </div>
                                                                )} />
                                                            <ErrorMessage name="cliente.nodoc" component={FormError} />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label>Complemento</label>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <input type="text" className="form-control form-control-sm"
                                                                        name="complemento"
                                                                        value={formik.values.cliente.complemento ?? ""}
                                                                        onChange={(e) => onChangeCliente(e, formik)}
                                                                        onKeyUp={(e) => handleKeyUpNodoc(e, formik)}
                                                                        onKeyDown={(e) => { (e.key === 'Enter') && e.preventDefault() }}
                                                                    />
                                                                </div>
                                                                <div className="col-auto ps-0 ">
                                                                    <button className="btn btn-sm btn-light border" type="button"
                                                                        onClick={(e) => handleBuscarCliente(e, formik)}
                                                                    >
                                                                        <i className="fa-solid fa-magnifying-glass"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-2">
                                                <label>Tipo de Doc.</label>
                                                <Select
                                                    className="w-100"
                                                    name="cod_tipo_doc"
                                                    options={resources?.tipos_doc?.map(td => ({
                                                        label: td.descripcion,
                                                        value: parseInt(td.codigo_clasificador)
                                                    }))}
                                                    value={formik.values.cliente.cod_tipo_doc}
                                                    onChange={(value) => {
                                                        formik.setFieldValue('cliente', { ...formik.values.cliente, cod_tipo_doc: value })
                                                    }
                                                    }
                                                ></Select>
                                                <ErrorMessage name="cliente.cod_tipo_doc" component={FormError} />
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-2">
                                                <label>Razón Social</label>
                                                <input type="text" className="form-control form-control-sm"
                                                    name="razon_social"
                                                    value={formik.values.cliente.razon_social}
                                                    onChange={(e) => onChangeCliente(e, formik)}
                                                />
                                                <ErrorMessage name="cliente.razon_social" component={FormError} />
                                            </div>
                                            <div className="mb-2">
                                                <label>Email</label>
                                                <input type="email" className="form-control form-control-sm"
                                                    name="email"
                                                    value={formik.values.cliente.email ?? ""}
                                                    onChange={(e) => onChangeCliente(e, formik)}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="mb-2 d-flex justify-content-between">
                                    <div>
                                        <span className="text-danger">(*) </span>
                                        <u>Campo obligatorio.</u>
                                    </div>
                                    <div className="d-flex">
                                        <ErrorMessage name="total" component={FormError} />
                                        <div className="mx-3">
                                            <b>Total:</b>
                                            <span className="ms-2" style={{ fontSize: '1.2em' }}>
                                                {parseFloat(formik.values.total).toFixed(2)}
                                            </span>
                                        </div>
                                        <button type="submit" className="btn btn-sm btn-green px-4">
                                            {
                                                (loading) ? (
                                                    <Spin size="small" />
                                                ) : "Facturar"
                                            }
                                        </button>
                                        <button type="button" className="btn btn-sm btn-secondary px-4 ms-1"
                                            onClick={() => { formikRef.current.resetForm(); }}
                                        >
                                            Limpiar
                                        </button>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md" style={{overflowX: 'auto'}}>
                                        <VentasProductos
                                            onSelProduct={(producto) => handleSelProduct(producto, formik)}
                                        />
                                    </div>
                                    <div className="col-md" style={{overflowX: 'auto'}}>
                                        <VentaItemsComponent
                                            items={formik.values.items}
                                            onRemove={(index) => removeVentaItem(index, formik)}
                                        />
                                    </div>
                                </div>

                                <AddItemModal
                                    showAddItem={showAddItem}
                                    onCancel={hideAddItemModel}
                                    ventaItem={ventaItem}
                                    onAccept={(values) => handle_additem(values, formik)}
                                />
                            </Form>
                        )
                    }
                </Formik>
                <ModalPagos after_submit={after_submit} />
            </div>
        </div>
    );
}

export default FormComponent;

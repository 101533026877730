import { Table } from "antd";
import { sprintf } from "sprintf-js";
const { Column } = Table;

const VentaItemsComponent = (props) => {
    const { items, onRemove } = props;

    const total = items.reduce((t, item) => t + parseFloat(item.subtotal), 0);

    const Footer = () => {
        return (
            <div className="d-flex justify-content-end">
                <div>
                    <b>TOTAL:</b>
                </div>
                <div className="text-end" style={{ width: '100px' }}>
                    <b>{parseFloat(total).toFixed(2) }</b>
                </div>
            </div>
        );
    }

    const ActionButtons = ({index}) => {
        return (
            <>
                <button className="btn btn-sm btn-outline-danger py-0"
                    onClick={()=> onRemove(index)} type="button"
                >
                    <i className="fa fa-xmark"></i>
                </button>
            </>
        );
    }

    return (
        <div>
            <div style={{ height: '40px' }}></div>
            <Table
                size="small"
                dataSource={items}
                rowKey="index"
                footer={Footer}
                scroll={{ y: 400, }}
                bordered
            >
                <Column title="·" dataIndex="index" align="center" 
                    render={(value) => (<ActionButtons index={value} />)}
                    width={50}
                />
                <Column title="#" dataIndex="index" align="center" 
                    render={(value) => (<>{ value }</>)}
                    width={40}
                />

                <Column title="Cantidad" dataIndex="cantidad" align="right" 
                    width={70}
                    render={(value) => (<b>{value}</b>) }
                />
                <Column title="Descripción" dataIndex="producto"
                    render={(value) => (<>{value.descripcion}</>)}
                    width={200}
                />
                <Column title="P.U." dataIndex="precio_u" align="right"
                    width={80}
                />
                <Column title="Descuento" dataIndex="descuento_total" align="right"
                    width={80}
                />
                <Column title="SubTotal" dataIndex="subtotal" align="right"
                    width={80}
                />
            </Table>            
        </div>
    );
}

export default VentaItemsComponent;
import { connector } from "../../common/connector";

export const reducer = (state, action) => {
    switch (action.type) {
        case 'FILL':
            return action.payload;

        case 'ADD':
            return [...state, action.payload];
        
        case 'UPDATE':
            return state.map(it => {
                if(it.id === action.payload.id) { return action.payload; }
                else { return it; }
            });
        
        case 'REMOVE':
            return state.filter(it => it.id != action.payload);
        
        default: return state;
    }
}

export const getItem = () => {
    return connector.get('api/info_tienda');
}

export const updateItem = (data) => {
    return connector.put(`api/update_info_tienda`, data)
}
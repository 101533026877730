import { Modal } from "antd";
import { Formik, Form, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import FormError from "../../../common/FormError";

const AddItemModal = (props) => {
    const {
        showAddItem,
        onCancel,
        ventaItem,
        onAccept
    } = props;

    const [initialValues, setInitialvalues] = useState(ventaItem);

    useEffect(() => {
        setInitialvalues(ventaItem);
    }, [ventaItem]);

    const validateSchema = Yup.object({
        producto: Yup.object()
            .required('El campo es obligario.'),
        cantidad: Yup.number()
            .moreThan(0, 'El valor debe ser mayor a 0.')
            .required('El campo es obligario.'),
        precio_u: Yup.number()
            .moreThan(0, 'El valor debe ser mayor a 0.')
            .required('El campo es obligario.'),
        subtotal: Yup.number()
            .moreThan(0, 'El valor debe ser mayor a 0.')
            .required('El campo es obligario.'),
    });

    const updateItemVenta = (e, formik) => {
        let subtotal = 0;
        let descuento_total = 0;

        switch (e.target.name) {
            case "cantidad":
                let cnt = (e.target.value) ? parseFloat(e.target.value) : 0;
                cnt = Math.max(cnt, 0);
                formik.setFieldValue('cantidad', cnt);
                descuento_total = parseFloat(formik.values.descuento_pu * cnt).toFixed(2);
                subtotal = parseFloat((cnt * formik.values.precio_u) - (descuento_total)).toFixed(2);
                formik.setFieldValue('descuento_total', descuento_total);
                formik.setFieldValue('subtotal', subtotal);
                break;
            case "precio_u":
                let pu = (e.target.value) ? parseFloat(e.target.value) : 0;
                pu = Math.max(pu, 0);
                formik.setFieldValue('precio_u', pu);
                subtotal = parseFloat((pu * formik.values.cantidad) - (formik.values.descuento_total)).toFixed(2)
                formik.setFieldValue('subtotal', subtotal);
                break;
            case "descuento_pu":
                let descuento_pu = (e.target.value) ? parseFloat(e.target.value) : 0;
                descuento_pu = Math.max(descuento_pu, 0);
                formik.setFieldValue('descuento_pu', descuento_pu);
                descuento_total = parseFloat(descuento_pu * formik.values.cantidad).toFixed(2);
                subtotal = parseFloat((formik.values.cantidad * formik.values.precio_u) - (descuento_total)).toFixed(2);
                formik.setFieldValue('descuento_total', descuento_total);
                formik.setFieldValue('subtotal', subtotal);
                break;
            default:
                break;
        }
    }

    return (
        <Modal
            title="Agregar"
            open={showAddItem}
            onCancel={() => onCancel()}
            destroyOnClose={true}
            footer={null}
        >
            <Formik
                initialValues={initialValues}
                onSubmit={onAccept}
                validationSchema={validateSchema}
                enableReinitialize={true}
            >
                {
                    (formik) => (
                        <Form>
                            <div>
                                <div className="row mb-2">
                                    <div className="col-md-5">
                                        Producto
                                    </div>
                                    <div className="col-md">
                                        <b>({ventaItem.producto?.codigo})</b>
                                        <i className="ms-1">
                                            {ventaItem.producto?.descripcion}
                                        </i>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md-5">
                                        Cantidad:
                                    </div>
                                    <div className="col-md">
                                        <input className="form-control form-control-sm" type="number" step="any"
                                            min={0}
                                            name="cantidad"
                                            value={formik.values.cantidad}
                                            onChange={(e) => updateItemVenta(e, formik)}
                                        />
                                        <ErrorMessage name="cantidad" component={FormError} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md-5">
                                        Precio U.
                                    </div>
                                    <div className="col-md">
                                        <input className="form-control form-control-sm" type="number" step="any"
                                            min={0}
                                            name="precio_u"
                                            value={formik.values.precio_u}
                                            onChange={(e) => updateItemVenta(e, formik)}
                                        />
                                        <ErrorMessage name="precio_u" component={FormError} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md-5">
                                        Subtotal sin descuento
                                    </div>
                                    <div className="col-md">
                                        <input className="form-control form-control-sm" type="number" step="any"
                                            min={0}
                                            name="precio_u"
                                            value={formik.values.precio_u * formik.values.cantidad}
                                            /* onChange={(e) => updateItemVenta(e, formik)} */
                                            disabled
                                        />
                                        <ErrorMessage name="precio_u" component={FormError} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md-5">
                                        Descuento por Unidad
                                    </div>
                                    <div className="col-md">
                                        <input className="form-control form-control-sm" type="number" step="any"
                                            min={0}
                                            name="descuento_pu"
                                            value={formik.values.descuento_pu}
                                            onChange={(e) => updateItemVenta(e, formik)}
                                        />
                                        <ErrorMessage name="descuento_pu" component={FormError} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md-5">
                                        Descuento total
                                    </div>
                                    <div className="col-md">
                                        <input className="form-control form-control-sm" type="number" step="any"
                                            value={formik.values.descuento_total}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md-5">
                                        SubTotal
                                    </div>
                                    <div className="col-md">
                                        <input className="form-control form-control-sm" type="number" step="any"
                                            value={formik.values.subtotal}
                                            disabled={true}
                                        />
                                        <ErrorMessage name="subtotal" component={FormError} />
                                    </div>
                                </div>
                                <div className="mb-2 text-end">
                                    <button className="btn btn-sm btn-dark px-3" type="submit" >
                                        Agregar
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </Modal>
    );
}

export default AddItemModal;

import { connector } from "../../common/connector";

export const defaultItem = {
    id: 0,
    nit: "",
    razon_social: "",
    municipio: "",
    telefono: "",
    direccion: "",
    pruebas: true,
    t_admin: {
        id: 0,
        nombre: "",
        email: "",
        password: "password",
        estado: 1,
    }
}

export const reducer = (state, action) => {
    switch (action.type) {
        case 'FILL':
            return action.payload;

        case 'ADD':
            return [...state, action.payload];
        
        case 'UPDATE':
            return state.map(it => {
                if(it.id === action.payload.id) { return action.payload; }
                else { return it; }
            });
        
        case 'REMOVE':
            return state.filter(it => it.id != action.payload);
        
        default: return state;
    }
}

export const getItems = (params) => {
    return connector.get('api/tiendas', { params: params });
}

export const createItem = (data) => {
    return connector.post('api/tiendas', data);
}

export const updateItem = (data) => {
    return connector.put(`api/tiendas/${data.id}`, data)
}

export const deleteItem = (id) => {
    return connector.delete(`api/tiendas/${id}`);
}
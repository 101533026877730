import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState, useContext } from "react";
import * as Yup from "yup";
import { Spin } from 'antd';
import FormError from "../../common/FormError";
import { EditableContext } from "../../common/EditableContextProvider";

const FormComponent = ({ errors }) => {
    const { editItem, onFormAction } = useContext(EditableContext);
    const [initialValues, setInitialvalues] = useState(editItem);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setInitialvalues(editItem);
    }, [editItem]);

    const handleSubmit = async (values, formik) => {
        try {
            setLoading(true);
            await onFormAction(values);
            setInitialvalues(values);
        } catch (e) {
            if (e.code === "REQ_FAIL") {
                if (e.errors.nit) { formik.setFieldError("nit", e.errors.nit[0]); }
                if (e.errors["t_admin.email"]) { formik.setFieldError("t_admin.email", e.errors["t_admin.email"][0]); }
                console.log(e);
            }
        }
        finally { setLoading(false); }
    }

    const validateSchema = Yup.object({
        nit: Yup.string()
            .max(50, 'El texto es demasiado largo')
            .required('El campo nombre es obligario.'),
        razon_social: Yup.string()
            .max(200, 'El texto es demasiado largo')
            .required('El campo es obligario.'),
        t_admin: Yup.object({
            nombre: Yup.string()
                .max(200, 'El texto es demasiado largo')
                .required('El campo nombre es obligario.'),
            email: Yup.string()
                .max(191, 'El texto es demasiado largo')
                .required('El campo nombre es obligario.')
        }),
        municipio: Yup.string()
            .max(200, 'El texto es demasiado largo')
            .required('El campo nombre es obligario.'),
        telefono: Yup.string()
            .max(100, 'El texto es demasiado largo')
            .required('El campo nombre es obligario.'),
        direccion: Yup.string()
            .max(200, 'El texto es demasiado largo')
            .required('El campo nombre es obligario.'),
    });

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validateSchema}
                enableReinitialize={true}
            >
                {
                    (formik) => (
                        <Form>
                            <fieldset className="border rounded p-2 mb-2" disabled={loading} >
                                <div className="pb-1 fw-bold">Información de Tienda</div>
                                <div className="py-1">
                                    <span>ID: </span>
                                    <span className="fw-bold ms-1">{sprintf('%04d', editItem.id)}</span>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md">
                                        <label htmlFor="nit" className="form-label">
                                            NIT
                                            <span className="text-danger">(*)</span>
                                        </label>
                                        <Field id="nit" name="nit" type="text"
                                            className="form-control form-control-sm text-uppercase"
                                            />
                                        <ErrorMessage name="nit" component={FormError} />
                                    </div>
                                    <div className="col-md">
                                        <label htmlFor="razon_social" className="form-label">
                                            Razón Social
                                            <span className="text-danger">(*)</span>
                                        </label>

                                        <input type="text"
                                            className="form-control form-control-sm text-uppercase"
                                            value={formik.values.razon_social}
                                            onChange={(e) => { formik.setFieldValue('razon_social', e.target.value.toUpperCase()) }}
                                            onBlur={() => formik.setFieldTouched('razon_social')}
                                        />
                                        <ErrorMessage name="razon_social" component={FormError} />

                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md">
                                        <label htmlFor="municipio" className="form-label">
                                            Municipio
                                            <span className="text-danger">(*)</span>
                                        </label>
                                        <input type="text"
                                            className="form-control form-control-sm text-uppercase"
                                            value={formik.values.municipio}
                                            onChange={(e) => { formik.setFieldValue('municipio', e.target.value.toUpperCase()) }}
                                            onBlur={() => formik.setFieldTouched('municipio')}
                                        />
                                        <ErrorMessage name="municipio" component={FormError} />
                                    </div>
                                    <div className="col-md">
                                        <label htmlFor="telefono" className="form-label">
                                            Teléfono
                                            <span className="text-danger">(*)</span>
                                        </label>
                                        <Field id="telefono" name="telefono" type="telefono"
                                            className="form-control form-control-sm" />
                                        <ErrorMessage name="telefono" component={FormError} />
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="direccion" className="form-label">
                                        Dirección
                                        <span className="text-danger">(*)</span>
                                    </label>
                                    <textarea type="text"
                                            className="form-control form-control-sm text-uppercase"
                                            value={formik.values.direccion}
                                            onChange={(e) => { formik.setFieldValue('direccion', e.target.value.toUpperCase()) }}
                                            onBlur={() => formik.setFieldTouched('direccion')}
                                    ></textarea>
                                    <ErrorMessage name="direccion" component={FormError} />
                                </div>
                            </fieldset>
                            <fieldset className="border rounded p-2 mb-2" disabled={loading}>
                                <div className="pb-1 fw-bold">Información de Usuario</div>
                                <div className="mb-2">
                                    <div className="py-1">
                                        <span>ID(Usuario): </span>
                                        <span className="fw-bold ms-1">{sprintf('%04d', editItem.t_admin.id)}</span>
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="t_admin.nombre" className="form-label">
                                            Nombre
                                            <span className="text-danger">(*)</span>
                                        </label>
                                        <Field id="t_admin.nombre" name="t_admin.nombre" type="text" className="form-control form-control-sm" />
                                        <ErrorMessage name="t_admin.nombre" component={FormError} />
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-md">
                                            <label htmlFor="t_admin.email" className="form-label">
                                                Email
                                                <span className="text-danger">(*)</span>
                                            </label>
                                            <Field id="t_admin.email" name="t_admin.email" type="email" className="form-control form-control-sm" />
                                            <ErrorMessage name="t_admin.email" component={FormError} />
                                        </div>
                                        <div className="col-md">
                                            <label htmlFor="t_admin.password" className="form-label">
                                                Contraseña
                                                <span className="text-danger">(*)</span>
                                            </label>
                                            <Field id="t_admin.password" name="t_admin.password" type="password" className="form-control form-control-sm" />
                                            <ErrorMessage name="t_admin.password" component={FormError} />
                                        </div>
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="t_admin.estado" className="form-label" >
                                            <Field name="t_admin.estado" id="t_admin.estado" type="checkbox" className="form-checkbox me-2" />
                                            Activo
                                        </label>
                                        <ErrorMessage name="t_admin.estado" component={FormError} />
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="p-2" disabled={loading}>
                                <div className="mb-2 d-flex justify-content-between">
                                    <div>
                                        <span className="text-danger">(*) </span>
                                        <u>Campo obligatorio.</u>
                                    </div>
                                    <div className="d-flex flex-row">
                                        {/* <button type="button" className="btn btn-sm btn-light border border-secondary px-4 me-2"
                                            onClick={() => formik.resetForm()}
                                        >
                                            Reestablecer
                                        </button> */}
                                        <button type="submit" className="btn btn-sm btn-light border border-secondary px-4">
                                            {
                                                (loading) ? (
                                                    <Spin size="small" />
                                                ) : "Guardar"
                                            }
                                        </button>
                                    </div>
                                </div>
                            </fieldset>
                        </Form>
                    )
                }
            </Formik>
        </div>
    );
}

export default FormComponent;

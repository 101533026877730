import { Select, DatePicker } from "antd";
import { useContext, useState } from "react";
import { EditableContext } from "../../common/EditableContextProvider";
import dayjs from "dayjs";
import { Formik, Form, ErrorMessage } from "formik";
import FormError from "../../common/FormError";
import * as Yup from 'yup';

export const FormEventos = () => {
    const contingencia = useContext(EditableContext);
    const [cufds, setCufds] = useState([]);
    const [initialValues, setInitialValues] = useState({
        cod_evento: "",
        tienda_pv_id: "",
        cod_cufd: "",
        date_start: dayjs().subtract(5, 'days'),
        date_end: dayjs()
    });

    const handleSubmit = async (values, formik) => {
        await contingencia.regEvento({
            ...values,
            date_start: values.date_start.format('YYYY-MM-DD HH:mm:ss'),
            date_end: values.date_end.format('YYYY-MM-DD HH:mm:ss')
        });

        formik.resetForm();
    }

    const validationSchema = Yup.object({
        cod_evento: Yup.string()
            .required("El campo es requerido."),
        tienda_pv_id: Yup.string()
            .required("El campo es requerido."),
        cod_cufd: Yup.string()
            .required("El campo es requerido."),
        date_start: Yup.date()
            .required("El campo es requerido."),
        date_end: Yup.date()
            .required("El campo es requerido."),
    });

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
            >
                {
                    (formik) => (
                        <Form>
                            <fieldset disabled={contingencia.loadingRegEvento}>
                                <div className="mb-2">
                                    <label htmlFor="cod_evento">Evento Significativo</label>
                                    <Select
                                        id="cod_evento"
                                        options={[
                                            { value: "", label: "Seleccionar", disabled: true },
                                            ...contingencia.resources.eventos_significativos.map(es => (
                                                { value: es.codigo_clasificador, label: es.descripcion }
                                            ))
                                        ]}
                                        className="w-100"
                                        value={formik.values.cod_evento}
                                        onChange={(value) => formik.setFieldValue('cod_evento', value)}
                                    ></Select>
                                    <ErrorMessage name="cod_evento" component={FormError} />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="tienda_pv_id">Punto de Venta</label>
                                    <Select
                                        id="tienda_pv_id"
                                        options={[
                                            { value: "", label: "Seleccionar", disabled: true },
                                            ...contingencia.resources.venta_puntos.map(vp => (
                                                { value: vp.id, label: vp.nombre, item: vp }
                                            ))
                                        ]}
                                        className="w-100"
                                        onChange={(value, option) => {
                                            formik.setFieldValue('tienda_pv_id', value)
                                            setCufds(option.item.codigos_cufds);
                                        }}
                                        value={formik.values.tienda_pv_id}
                                    ></Select>
                                    <ErrorMessage name="tienda_pv_id" component={FormError} />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="cod_cufd">CUFD</label>
                                    <Select
                                        id="cod_cufd"
                                        options={[
                                            { value: "", label: "Seleccionar", disabled: true },
                                            ...cufds.map(cu => ({
                                                label: `ID:${cu.id} / ${dayjs(cu.fecha).format('DD-MM-YYYY HH:mm:ss')} / ${dayjs(cu.fecha_vigencia).format('DD-MM-YYYY HH:mm:ss')}`,
                                                value: cu.codigo,
                                                item: cu
                                            }))
                                        ]}
                                        className="w-100"
                                        value={formik.values.cod_cufd}
                                        onChange={(value, option) => {
                                            formik.setFieldValue('cod_cufd', value);
                                            formik.setFieldValue('date_start', dayjs(option.item.fecha));
                                            formik.setFieldValue('date_end', dayjs(option.item.fecha_vigencia));
                                        }}
                                    ></Select>
                                    <ErrorMessage name="cod_cufd" component={FormError} />
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md">
                                        <label htmlFor="date_start">Fecha Inicio</label>
                                        <DatePicker
                                            className="w-100"
                                            id="date_start"
                                            showTime
                                            value={formik.values.date_start}
                                            onChange={(value) => formik.setFieldValue('date_start', value)}
                                        ></DatePicker>
                                        <ErrorMessage name="date_start" component={FormError} />
                                    </div>
                                    <div className="col-md">
                                        <label htmlFor="date_end">Fecha Fin</label>
                                        <DatePicker
                                            className="w-100"
                                            id="date_end"
                                            showTime
                                            value={formik.values.date_end}
                                            onChange={(value) => formik.setFieldValue('date_end', value)}
                                        ></DatePicker>
                                        <ErrorMessage name="date_end" component={FormError} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <button type="submit" className="btn btn-sm btn-dark w-75 mx-auto">
                                        Registrar evento significativo
                                    </button>
                                </div>
                            </fieldset>
                        </Form>
                    )
                }
            </Formik>

        </div>
    );
}

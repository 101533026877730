import { Table } from "antd";
import { useEffect, useState, useRef, memo } from "react";
import useProductos from "../productos/useProductos";
const { Column } = Table;
import dayjs from "dayjs";
import { sprintf } from "sprintf-js";

const VentasProductos = ({onSelProduct}) => {
    const {
        items, fillItems, loading, tableParams
    } = useProductos();
    const searchInput = useRef(null);
    const [search, setSearch] = useState("");

    useEffect(() => {
        let controller = new AbortController();
        fillItems(tableParams, controller.signal);
        return () => { controller.abort(); }
    }, []);

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div className="p-1" onKeyDown={e => e.stopPropagation()}>
                <input type="text" className="form-control form-control-sm"
                    ref={searchInput}
                    placeholder='Escribe algo...'
                    value={(selectedKeys[0]) ? selectedKeys[0] : ""}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') { handleSearch(selectedKeys, confirm, dataIndex) }
                    }}
                />
                <div className="mt-1 d-flex justify-content-center">
                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    >
                        <span className="fas fa-search"></span>
                    </button>

                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => clearFilters && handleReset(clearFilters, setSelectedKeys, confirm)}
                    >
                        Reset
                    </button>

                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => { close(); }}
                    >
                        Salir
                    </button>
                </div>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) { setTimeout(() => searchInput.current?.select(), 100); }
        }
    });

    const onTableChange = (pagination, filters, sorter, extra) => {
        let params = {
            ...tableParams,
            pagination,
            filters,
            sorter,
        };
        fillItems(params);
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };

    const handleReset = (clearFilters, setSelectedKeys, confirm) => {
        clearFilters();
        setSelectedKeys([]);
        confirm();
    };

    const make_search = (e) => {
        let params = {
            ...tableParams,
            filters: {"producto": [search]},
        };
        fillItems(params);
    }

    const handleKeyUpSearch = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            setSearch(e.target.value);
            let params = {
                ...tableParams,
                filters: {"producto": [e.target.value]},
            };
            fillItems(params);
        }
    }

    const Monto = memo(({descuento}) => {
        let now = dayjs().format('YYYY-MM-DD');

        if(descuento) {
            if(now >= descuento.fecha_inicio && now <= descuento.fecha_fin) {
                return descuento.monto;
            }
        }
        return 0;
    });

    return (
        <div>
            <div className="row mb-2">
                <div className="col-auto pe-0 col-md-auto">
                    <label htmlFor="" className="form-label-sm">Buscar:</label>
                </div>
                <div className="col col-md">
                    <input type="text" className="form-control form-control-sm"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyUp={handleKeyUpSearch}
                    />
                </div>
                <div className="col-auto col-md-auto">
                    <button className="btn btn-sm btn-light border"
                        onClick={make_search} type="button"
                    >
                        Buscar...
                    </button>
                </div>
            </div>
            <Table
                size="small"
                dataSource={items}
                rowKey='id'
                loading={loading}
                onChange={onTableChange}
                pagination={{ ...tableParams.pagination, position: ['bottomCenter'], showSizeChanger: true }}
                scroll={{ y: 400, }}
                bordered
            >
                <Column title="·" dataIndex="id" key="buttons"
                    width={40}
                    render={(value, row) => (
                        <div className="w-100 text-center">
                            <button className="btn btn-sm btn-success border py-0"
                                onClick={() => onSelProduct(row)} type="button"
                            >
                                <i className="fa fa-arrow-right"></i>
                            </button>
                        </div>
                    )}
                />
                <Column title="ID" dataIndex="id" key="id" align="center" className="fw-bold"
                    sorter={true}
                    width={60}
                    render={(value) => sprintf('%04d', value)}
                />
                <Column title="Producto" dataIndex="id" key="producto"
                    width={250}
                    filterDropdown={getColumnSearchProps('producto').filterDropdown}
                    onFilterDropdownOpenChange={getColumnSearchProps('producto').onFilterDropdownOpenChange}
                    render={(value,row) => (
                    <>
                            <div className="fw-bold">{row.codigo}</div>
                            <div className="">{row.descripcion}</div>
                        </>
                    )}
                />
                <Column title="Precio en Bs." dataIndex="precio" key="precio"
                    align="right"
                    width={60}
                    render={(value) => (<>{ sprintf('%.02f', value) }</>)}
                />
                <Column title="Descuento en Bs." dataIndex="descuento" key="descuento"
                    align="right"
                    width={60}
                    render={(value) => (<Monto descuento={value} />)}
                />
            </Table>
        </div>
    );
}

export default VentasProductos;

import { Modal, Table } from "antd";
import { useState } from "react";
import { sprintf } from "sprintf-js";
const { Column } = Table;

const ShowItems = ({ children }) => {
    const [visible, setVisible] = useState(false);
    const [item, setItem] = useState({});

    const show = (data) => {
        setItem(data);
        setVisible(true);
    }

    const hide = () => {
        setVisible(false);
    }

    return (
        <Modal
            title="Items"
            open={visible}
            onCancel={hide}
            destroyOnClose={true}
            footer={null}
            scroll={{ y: 400, }}
        >
            {children({ show, hide })}
            <div>
                <div className="mb-3">
                    <span>ID:</span>
                    <span className="fw-bold ms-2">{sprintf("%04d", item?.id|0)}</span>
                </div>
                <Table
                    size="small"
                    dataSource={item?.items}
                    rowKey="id"
                    bordered
                    footer={() => (
                        <>
                            <b>Total: </b> {item?.total}
                        </>
                    )}
                >
                    <Column title="id" dataIndex="id" width={60} />
                    <Column title="cantidad" dataIndex="cantidad" width={60} align="right" />
                    <Column title="producto" dataIndex="producto" width={250}
                        render={(value) => (<>{value.descripcion}</>)}
                    />
                    <Column title="precio_unitario" dataIndex="precio_unitario" width={80} align="right" />
                    <Column title="subtotal" dataIndex="subtotal" width={80} align="right" />
                </Table>
            </div>
        </Modal>
    );
}

export default ShowItems;
import { connector } from "../../common/connector";

export const defaultItem = {
    id: 0,
    razon_social: "",
    cod_tipo_doc: "",
    nodoc: "",
    complemento: "",
    email: "",
    tipo_doc: ""
}

export const reducer = (state, action) => {
    switch (action.type) {
        case 'FILL':
            return action.payload;

        case 'ADD':
            return [...state, action.payload];
        
        case 'UPDATE':
            return state.map(it => {
                if(it.id === action.payload.id) { return action.payload; }
                else { return it; }
            });
        
        case 'REMOVE':
            return state.filter(it => it.id != action.payload);
        
        default: return state;
    }
}

export const getItems = (params) => {
    return connector.get('api/clientes', { params: params });
}

export const createItem = (data) => {
    return connector.post('api/clientes', data);
}

export const updateItem = (data) => {
    return connector.put(`api/clientes/${data.id}`, data)
}

export const deleteItem = (id) => {
    return connector.delete(`api/clientes/${id}`);
}

export const getResources = () => {
    return connector.get(`api/clientes/resources`);
}
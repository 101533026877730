import React from "react";
import { createRoot } from 'react-dom/client';
import ErrorBox from "../../common/ErrorBox";
import TableComponent from "./TableComponent";
import { useState, useEffect } from "react";
import { ConfigProvider, Modal, App, Tabs } from 'antd';
import { EditableContextProvider } from "../../common/EditableContextProvider";
import FormComponent from "./FormComponent";
import { customTheme } from "../../common/customTheme";
import useUsuarios from "./useUsuarios";

export default function Main() {
    const {
        editItem, fillItems, items, selectItem, newItem, saveItem, destroyItem, loading, tableParams,
        fillResources, resources, updatePuntoVenta
    } = useUsuarios();
    const [formVisible, setFormVisible] = useState(false);
    const { modal, notification } = App.useApp();

    useEffect(() => {
        Promise.all([
            fillItems(),
            fillResources()
        ])
    }, []);

    const handleTableChange = (pagination, filters, sorter, extra) => {
        let params = {
            ...tableParams,
            pagination,
            filters,
            sorter,
        };
        fillItems(params);
    }
    const handleFormAction = async (item) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (item === editItem) { notification.info({ description: "No hay cambios." }); resolve(); return; }
                await saveItem(item);
                notification.success({ description: "¡Guardado correcto!" });
                resolve();
            } catch (e) {
                if(e.code === "SERVER_ERROR") {
                    notification.error({ description: e.message });
                }
                reject(e);
            }
        });
    }
    const handleDeleteItem = (id) => {
        modal.confirm({
            content: `¿Eliminar ítem (ID: ${id}) ?`,
            onOk: async () => {
                try {
                    await destroyItem(id);
                    notification.info({ message: "¡Item eliminado!" });
                } catch ({ response }) {
                    notification.error({ message: "Ocurrió un error al eliminar el ítem." });
                    console.log(response.data);
                }
            }
        });
    }
    const addNewItem = () => { newItem(); showForm(); }
    const setUpdateItem = (item) => { selectItem(item); showForm(); }
    const showForm = () => { setFormVisible(true); }
    const hideForm = () => { setFormVisible(false); }

    const hasPuntoVenta = (tienda_pv_id) => {
        let found = editItem.tiendas_p_v.filter(pv => pv.id === tienda_pv_id);
        return (found.length > 0);
    }

    const checkbox_PuntoVenta_onchange = async (e, pv) => {
        let {value, checked} = e.target;
        console.log(value, checked);
        await updatePuntoVenta(pv, checked);
        fillItems();
    }

    return (
        <div>
            <div className='my-1 py-1'>
                <button className="btn btn-sm btn-green"
                    onClick={() => { addNewItem(); }}
                >
                    <i className="fa-solid fa-plus me-1"></i>
                    Agregar
                </button>
            </div>
            <TableComponent
                items={items}
                isLoading={loading}
                tableParams={tableParams}
                onTableChange={handleTableChange}
                updateItem={setUpdateItem}
                deleteItem={handleDeleteItem}
            />

            <Modal
                open={formVisible}
                onCancel={hideForm}
                destroyOnClose={true}
                footer={null}
            >
                <EditableContextProvider data={{
                    editItem: editItem,
                    onFormAction: handleFormAction,
                }}
                >
                    <Tabs
                        items={[
                            { key: "formulario", label: "Formulario",  children: (<FormComponent />) },
                            { key: "puntos_venta", label: "Puntos de Venta",  children: (
                                (editItem.id > 0)? (
                                <>
                                    {
                                        resources.venta_puntos.map(pv => (
                                            <div key={`pv_${pv.id}`}>
                                                <label htmlFor={`pv_${pv.id}`}>
                                                    <input type="checkbox" name="" id={`pv_${pv.id}`}
                                                        value={pv.id} className="me-1"
                                                        checked={hasPuntoVenta(pv.id)}
                                                        onChange={(e) => { checkbox_PuntoVenta_onchange(e, pv) }}
                                                    />
                                                    {pv.nombre}
                                                </label>
                                            </div>
                                        ))
                                    }
                                </>
                                ) : (<>Guarde el Usuario</>)
                            )},
                        ]}
                    ></Tabs>

                </EditableContextProvider>
            </Modal>
        </div>
    );
}

let main = document.getElementById('usuarios-main');
if (main) {
    const root = createRoot(main);
    root.render(
        <React.StrictMode>
            <ErrorBox>
                <ConfigProvider theme={customTheme}>
                    <App>
                        <Main />
                    </App>
                </ConfigProvider>
            </ErrorBox>
        </React.StrictMode>
    );
}

import { connector } from "../../common/connector"
import dayjs from "dayjs";

export const defaultItem = {
    id: 0,
    tienda_id: "",
    fecha: dayjs().format('YYYY-MM-DD'),
    tienda_pv_id: "",
    cliente_id: "",
    estado_factura: "P",
    total: 0,
    cliente: {
        id: 0,
        razon_social: "",
        cod_tipo_doc: "",
        nodoc: "",
        complemento: "",
        email: ""
    },
    items: [],
    
}

export const reducer = (state, action) => {
    switch (action.type) {
        case 'FILL':
            return action.payload;

        case 'ADD':
            return [...state, action.payload];
        
        case 'UPDATE':
            return state.map(it => {
                if(it.id === action.payload.id) { return action.payload; }
                else { return it; }
            });
        
        case 'REMOVE':
            return state.filter(it => it.id != action.payload);
        
        default: return state;
    }
}

export const getItems = (params) => {
    return connector.get('api/ventas', { params: params });
}

export const createItem = (data) => {
    return connector.post('api/ventas', data);
}

export const deleteItem = (id) => {
    return connector.delete(`api/ventas/${id}`);
}

export const searchCliente = (data) => {
    return connector.post(`api/ventas/cliente`, data);
}

export const getResources = () => {
    return connector.get('api/ventas/resources');
}

export const validate_nit = (nit) => {
    return connector.post('api/ventas/validate-nit', {nit});
}

export const anularFactura = (data) => {
    return connector.post('api/ventas/anular-factura', data);
}

export const revertirFactura = (venta_id) => {
    return connector.post('api/ventas/revertir-factura', {venta_id: venta_id});
}
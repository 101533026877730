import React from "react";
import { createRoot } from 'react-dom/client';
import ErrorBox from "../../common/ErrorBox";
import { useState, useEffect } from "react";
import { ConfigProvider, App } from 'antd';
import { EditableContextProvider } from "../../common/EditableContextProvider";
import FormComponent from "./FormComponent";
import { customTheme } from "../../common/customTheme";
import { getItem, updateItem } from "./functions";


export default function Main() {
    const { notification } = App.useApp();
    const [editItem, setEditItem] = useState({});

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            let { data } = await getItem();
            let {codigo, fecha_inicio, fecha_fin} = data.api_token;
            
            setEditItem({ codigo, fecha_inicio, fecha_fin });
        } catch (error) {
            console.error(error);
            notification.error({ description: "Ocurrió un error interno." });
        }
    }

    const handleFormAction = (values, formik) => {
        return new Promise(async (resolve, reject) => {
            try {
                await updateItem(values);
                notification.success({ description: "¡Guardado correcto!" });
                resolve();
            } catch (e) {
                if(e.code === "SERVER_ERROR") {
                    notification.error({ description: e.message });
                }
                reject(e);
            }
        });
    }

    return (
        <div>
            <EditableContextProvider data={{
                editItem: editItem,
                onFormAction: handleFormAction
            }}
            >
                <FormComponent />
            </EditableContextProvider>
        </div>
    );
}

let main = document.getElementById('token-api-main');
if (main) {
    const root = createRoot(main);
    root.render(
        <React.StrictMode>
            <ErrorBox>
                <ConfigProvider theme={customTheme}>
                    <App>
                        <Main />
                    </App>
                </ConfigProvider>
            </ErrorBox>
        </React.StrictMode>
    );
}

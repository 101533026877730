import { useContext, useEffect, useState } from "react";
import { EditableContext } from "../../common/EditableContextProvider";
import { Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormError from "../../common/FormError";
import dayjs from "dayjs";
import { Spin } from "antd";

export const Descuentos = () => {
    const { editItem, saveDescuento, deleteDescuento, loadingDescuento } = useContext(EditableContext);
    const defaultDescuento = {
        producto_id: editItem.id,
        fecha_inicio: dayjs().format('YYYY-MM-DD'),
        fecha_fin: dayjs().add(1, 'day').format('YYYY-MM-DD'),
        monto: 0
    };
    const [initialValues, setInitialvalues] = useState(editItem.descuento ?? defaultDescuento);
    const validateSchema = Yup.object({
        fecha_inicio: Yup.date()
            .required('El campo es obligatorio'),
        fecha_fin: Yup.date()
            .required('El campo es obligatorio'),
        monto: Yup.number()
            .min(0, 'El campo debe ser mayor que 0')
            .required('El campo es obligatorio'),
    });

    useEffect(() => {
        setInitialvalues(editItem.descuento?? defaultDescuento);
    }, [editItem]);

    const handleSubmit = async (values) => {
        await saveDescuento(values);
    }

    const handleDelete = async () => {
        await deleteDescuento();
    }

    return (
        <div>
            {
                (editItem.id > 0) ? (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validateSchema}
                        enableReinitialize={true}
                    >
                        {
                            (formik) => (
                                <Form>
                                    <fieldset disabled={loadingDescuento}>
                                        <div className="row mb-2">
                                            <div className="col">
                                                <label htmlFor="fecha_inicio" className="form-label">
                                                    Fecha Inicio
                                                    <span className="text-danger">(*)</span>
                                                </label>
                                                <input type="date" id="fecha_inicio" name="fecha_inicio" className="form-control form-control-sm"
                                                    value={formik.values.fecha_inicio}
                                                    onChange={(e) => formik.setFieldValue('fecha_inicio', e.target.value)}
                                                />
                                                <ErrorMessage name="fecha_inicio" component={FormError} />
                                            </div>
                                            <div className="col">
                                                <label htmlFor="fecha_fin" className="form-label">
                                                    Fecha Fin
                                                    <span className="text-danger">(*)</span>
                                                </label>
                                                <input type="date" id="fecha_fin" name="fecha_fin" className="form-control form-control-sm"
                                                    value={formik.values.fecha_fin}
                                                    onChange={(e) => formik.setFieldValue('fecha_fin', e.target.value)}
                                                />
                                                <ErrorMessage name="fecha_fin" component={FormError} />
                                            </div>
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="monto" className="form-label">
                                                Monto en Bs.
                                                <span className="text-danger">(*)</span>
                                            </label>
                                            <input type="number" id="monto" name="monto" className="form-control form-control-sm"
                                                value={formik.values.monto}
                                                onChange={(e) => formik.setFieldValue('monto', e.target.value)}
                                            />
                                            <ErrorMessage name="monto" component={FormError} />
                                        </div>
                                        <div className="mb-2 d-flex justify-content-between">
                                            <div>
                                                <span className="text-danger">(*) </span>
                                                <u>Campo obligatorio.</u>
                                            </div>
                                            <button type="submit" className="btn btn-sm btn-light border border-secondary px-4">
                                                {
                                                    (loadingDescuento) ? (
                                                        <Spin size="small" />
                                                    ) : "Guardar"
                                                }
                                            </button>
                                        </div>
                                    </fieldset>
                                </Form>
                            )
                        }
                    </Formik>
                ) : (<>Guarde el producto.</>)
            }
            {
                (editItem.descuento) ? (
                    <div className="text-end">
                        <button className="btn btn-sm btn-link text-danger"
                            onClick={() => handleDelete()}
                        >
                            <span className="fas fa-xmark me-1"></span>
                            Eliminar descuento
                        </button>
                    </div>
                ) : (<></>)
            }
        </div>
    );
}
import React, { useRef } from "react";
import { createRoot } from 'react-dom/client';
import ErrorBox from "../../common/ErrorBox";
import TableComponent from "./TableComponent";
import { useState, useEffect } from "react";
import { ConfigProvider, Modal, App } from 'antd';
import { EditableContextProvider } from "../../common/EditableContextProvider";
import FormComponent from "./FormComponent";
import { customTheme } from "../../common/customTheme";
import useVentas from "./useVentas";
import useClientes from "../clientes/useClientes";
import ShowItems from '../ventas/modals/ShowItems';
import AnularFactura from "./modals/AnularFactura";

export default function Main() {
    const {
        editItem, fillItems, items, selectItem, newItem, saveItem, destroyItem,
        loading, tableParams, searchNit, fillResources: fillVentasResources, resources: VentasResources,
        validateNit, revertir, pagos, fechas, updateFecha, defaultPuntoVenta
    } = useVentas();
    const { fillResources, resources } = useClientes();

    const [formVisible, setFormVisible] = useState(false);
    const { modal, notification } = App.useApp();

    const modalActions = useRef();
    const delModalActions = useRef();

    useEffect(() => {
        Promise.all([
            fillResources(),
            fillVentasResources(),
            fillItems(),
        ]);
    }, []);

    useEffect(() => {
        newItem();
    }, [defaultPuntoVenta]);

    const handleTableChange = (pagination, filters, sorter, extra) => {
        let params = {
            ...tableParams,
            pagination,
            filters,
            sorter,
        };
        fillItems(params);
    }
    const handleFormAction = async (item) => {

        return new Promise(async (resolve, reject) => {
            try {
                if (item === editItem) { notification.info({ description: "No hay cambios." }); resolve(); return; }
                let v_id = await saveItem(item);
                pagos.hidePagosModal();
                hideForm();
                notification.success({ description: "¡Guardado correcto!" });
                window.open(`/factura/pdf/${v_id}`, "_blank", "noreferrer");
                resolve();
            } catch (e) {
                if (e.code === "SERVER_ERROR") {
                    notification.error({ description: e.message });
                }
                reject(e);
            }
        });
    }
    const handleDeleteItem = (id) => {
        delModalActions.current.show(id);
    }

    const handleRevertir = (id) => {
        Modal.confirm({
            title: "Confirmar",
            content: "¿Desea revertir la anulación?",
            onOk: async () => {
                await revertir(id);
            }
        });
    }

    const addNewItem = () => { newItem(); showForm(); }
    const setUpdateItem = (item) => { selectItem(item); showForm(); }
    const showForm = () => { setFormVisible(true); }
    const hideForm = () => { setFormVisible(false); }

    return (
        <div>

            {/* <Modal title="Formulario"
                open={formVisible}
                onCancel={hideForm}
                destroyOnClose={true}
                footer={null}
                width={1200}
                centered
            > */}
                <EditableContextProvider data={{
                    editItem: editItem,
                    onFormAction: handleFormAction,
                    resources,
                    searchNit,
                    puntosVenta: VentasResources.puntosVenta,
                    validateNit,
                    pagos
                }}
                >
                    <FormComponent />
                </EditableContextProvider>
            {/* </Modal> */}
        </div>
    );
}

let main = document.getElementById('ventas-usuario-main');
if (main) {
    const root = createRoot(main);
    root.render(
        <React.StrictMode>
            <ErrorBox>
                <ConfigProvider theme={customTheme}>
                    <App>
                        <Main />
                    </App>
                </ConfigProvider>
            </ErrorBox>
        </React.StrictMode>
    );
}

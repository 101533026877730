import { useReducer, useState } from "react";
import {
    getItems, createItem, defaultItem, deleteItem, reducer,
    searchCliente, getResources, validate_nit, anularFactura,
    revertirFactura
} from "./functions";
import dayjs from "dayjs";

const useVentas = () => {
    const [items, dispatch] = useReducer(reducer);
    const [editItem, setEditItem] = useState(defaultItem);
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: { "current": 1, "pageSize": 20, "total": 0 },
        filters: {},
        sorter: {},
    });
    const [fechas, setFechas] = useState({
        fecha_inicio: dayjs().startOf('month').format('YYYY-MM-DD'),
        fecha_fin: dayjs().endOf('day').format('YYYY-MM-DD'),
    });

    const [resources, setResources] = useState({
        puntosVenta: [],
        motivosAnulacion: [],
        metodosPago: []
    });
    const [defaultPuntoVenta, setDefaultPuntoVenta] = useState([]);
    const monedas = [
        { cod_tipo_moneda: 1, descripcion: "BOLIVIANO", tipoCambio: 1},
        { cod_tipo_moneda: 46, descripcion: "DÓLAR ESTADOUNIDENSE", tipoCambio: 6.96},
    ];

    const fillItems = async (params = tableParams, currentFechas = fechas) => {
        try {
            setLoading(true);
            let { data } = await getItems({...params, fechas: currentFechas});
            let { rows, total, current } = data;

            dispatch({ type: "FILL", payload: rows });
            setTableParams(prev => {
                return {
                    ...prev,
                    ...params,
                    pagination: {
                        ...prev.pagination,
                        ...params.pagination,
                        total: total,
                        current: current
                    }
                }
            });
        } catch (error) { throw new Error(error); }
        finally { setLoading(false); }
    }
    const fillResources = async () => {
        try {
            let {data} = await getResources();
            setResources(prev => ({
                puntosVenta: data.venta_puntos,
                motivosAnulacion: data.motivos_anulacion,
                metodosPago: data.metodos_pago
            }));

            let pv_default = data.venta_puntos.find(pv => pv.codigo === 0);
            setDefaultPuntoVenta(pv_default);
        } catch (error) { console.log(error.message); }
    }
    const newItem = () => {
        setEditItem({
            ...defaultItem,
            tienda_pv_id: defaultPuntoVenta.id,
            nit_exception: false
        });
    }

    const saveItem = async (item) => {
        let item_action = createItem(item);
        try {
            setLoading(true);
            let res = await item_action;
            if (item.id === 0) {
                item.id = res.data.id;
                /* dispatch({ type: "ADD", payload: item }); */
                fillItems();
            } else {
                dispatch({ type: "UPDATE", payload: item });
            }
            /* setEditItem({...defaultItem}); */
            return item.id;
        } catch ( error) {
            let { status, data } = error.response;
            if(status === 422) {
                let { errors } = data;
                throw { code: 'REQ_FAIL', message: 'Error en la validación.', errors: errors };
            } else {
                throw { code: 'SERVER_ERROR', message: 'Error interno.' };
            }

        } finally { setLoading(false); }
    }

    const selectItem = (item) => {
        let {usuario,tienda_pv,...rest} = item;
        setEditItem({...rest});
    }

    const destroyItem = async (id, cod_motivo) => {
        try {
            await anularFactura(id, cod_motivo);
            fillItems();
        } catch (error) { console.log(error); }
    }

    const searchNit = async (values, formik) => {
        try {
            let res = await searchCliente(values);
            let {data, status} = res;

            if(status === 200) {
                formik.setFieldValue('cliente', data);
                formik.setFieldValue('cliente_id', data.id);
                //setEditItem(prev => ({...prev, cliente: data, cliente_id: data.id }))
            } else {
                formik.setFieldValue('cliente', {
                    ...defaultItem.cliente,
                    nodoc: defaultItem.cliente.nodoc
                });
                formik.setFieldValue('cliente_id', 0);

                /* setEditItem(prev => ({
                    ...prev,
                    cliente: {
                        ...defaultItem.cliente,
                        nodoc: prev.cliente.nodoc
                    },
                    cliente_id: 0
                })) */;
            }
        } catch (error) { console.log(error.message); }
    }

    const validateNit = async (nit) => {
        try {
            let res = await validate_nit(nit);
            return res.data.isValid;
        } catch (error) { console.error(error.message); }
    }

    const revertir = async(id) => {
        try {
            await revertirFactura(id);
            fillItems();
        } catch (error) { console.log(error); }
    }


    // #region pagos
    const [pagosModalVisibility, setPagosModalVisibility] = useState(false);
    const [preItem, setPreItem] = useState({});

    const showPagosModal = (item) => {
        setPreItem(item);
        setPagosModalVisibility(true);
     }
    const hidePagosModal = () => { setPagosModalVisibility(false); }

    // #endregion pagos

    const updateFecha = (key, value) => {
        setFechas(prev => ({
            ...prev,
            [key]: value
        }));
    }

    return {
        editItem,
        fillItems,
        items,
        selectItem,
        newItem,
        saveItem,
        destroyItem,
        loading,
        tableParams,
        searchNit,
        resources,
        fillResources,
        defaultPuntoVenta,
        validateNit,
        revertir,
        pagos: {
            pagosModalVisibility,
            showPagosModal,
            hidePagosModal,
            preItem,
            resources,
            loading,
            monedas
        },
        fechas,
        updateFecha
    };

}

export default useVentas;

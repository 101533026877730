import React, { useRef } from "react";
import { createRoot } from 'react-dom/client';
import { useState, useEffect } from "react";
import { ConfigProvider, Table, App, Select } from 'antd';
import { EditableContextProvider } from "../../common/EditableContextProvider";
import { customTheme } from "../../common/customTheme";
import useContingencia from "./useContingencia";
import ErrorBox from "../../common/ErrorBox";
import { sprintf } from "sprintf-js";
const { Column } = Table;
import { FormEventos } from "./FormEventos";
import { TableEventos } from "./TableEventos";

export default function Main() {
    const contingencia = useContingencia();

    useEffect(() => {
        Promise.all([contingencia.fillResources()]);
    }, []);

    return (
        <EditableContextProvider data={contingencia} >
            <div className="row mt-3 py-2">
                <div className="col-md-6">
                    <FormEventos />
                </div>
                <div className="col-md-6">
                    <TableEventos />
                </div>
            </div>
        </EditableContextProvider>
    );
}

let main = document.getElementById('cafc-main');
if (main) {
    const root = createRoot(main);
    root.render(
        <React.StrictMode>
            <ErrorBox>
                <ConfigProvider theme={customTheme}>
                    <App>
                        <Main />
                    </App>
                </ConfigProvider>
            </ErrorBox>
        </React.StrictMode>
    );
}

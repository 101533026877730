import { Modal, Select, Spin } from "antd";
import { useContext, useRef, useState, useCallback } from "react";
import { EditableContext } from "../../../common/EditableContextProvider";
import { Form, Formik, ErrorMessage } from "formik";
import { IMaskInput, IMaxInput } from "react-imask";
import * as Yup from "yup";
import FormError from "../../../common/FormError";
import dayjs from "dayjs";

export const Pagos = ({ after_submit }) => {
    const { pagos, onFormAction } = useContext(EditableContext);
    const [pagoDetails, setPagoDetails] = useState({
        esPagoTarjeta: false,
        esPagoGiftcard: false
    });

    const getInitialValues = useCallback(() => {
        return {
            ...pagos.preItem,
            pago: {
                codigoMetodoPago: "1",
                numeroTarjeta: "0000-0000-0000-0000",
                montoTotal: pagos.preItem.total,
                montoGiftcard: 0,
                montoTotalMoneda: pagos.preItem.total,
                codMoneda: 1,
                tipoCambio: 1,
                montoAPagar: pagos.preItem.total,
                creditoFiscal: pagos.preItem.total,
                descuento: 0,
            },
            cafc_generate: {
                isActive: false,
                cod_cufd_id: 0,
                fecha: dayjs().format('YYYY-MM-DD HH:mm:ss')
            }
        };
    }, [pagos.preItem]);

    const [initialValues, setInitialvalues] = useState({
        ...pagos.preItem,
        pago: {
            codigoMetodoPago: "1",
            numeroTarjeta: "0000-0000-0000-0000",
            montoTotal: pagos.preItem.total,
            montoGiftcard: 0,
            montoTotalMoneda: pagos.preItem.total,
            codMoneda: 1,
            tipoCambio: 1,
            montoAPagar: pagos.preItem.total,
            creditoFiscal: pagos.preItem.total,
            descuento: 0,
        },
        cafc_generate: {
            isActive: false,
            cod_cufd_id: 0,
            fecha: dayjs().format('YYYY-MM-DD HH:mm:ss')
        }
    });

    const handleSubmit = async (values, { resetForm }) => {
        await onFormAction(values);
        after_submit();
        resetForm();
    }

    const setCambioMoneda = (montoPagar, CodtipoMoneda, formik) => {
        let selMoneda = pagos.monedas.find(m => m.cod_tipo_moneda === CodtipoMoneda);
        let totalMoneda = (montoPagar / selMoneda.tipoCambio).toFixed(2);
        formik.setFieldValue('pago.tipoCambio', selMoneda.tipoCambio);
        formik.setFieldValue('pago.montoTotalMoneda', totalMoneda);
    }

    const validateSchema = Yup.object({
        pago: Yup.object({
            montoAPagar: Yup.number()
                .moreThan(0, 'El monto a pagar debe ser mayor a 0.')
                .required('El campo es obligario.'),
            descuento: Yup.number()
                .min(0, 'El monto a pagar debe ser mayor o igual a 0.')
        })
    });

    const calculateMontoTotal = (pago, formik) => {
        let montoTotal = pago.montoTotal;
        let descuento = pago.descuento;
        let giftcard = (pagoDetails.esPagoGiftcard) ? pago.montoGiftcard : 0;

        let montoAPagar = (montoTotal - descuento);
        let creditoFiscal = (montoAPagar - giftcard);

        formik.setFieldValue('pago.montoAPagar', Math.max(0, montoAPagar));
        formik.setFieldValue('pago.creditoFiscal', Math.max(0, creditoFiscal));

        setCambioMoneda(montoAPagar, formik.values.pago.codMoneda, formik);
    }

    const handlePagoDetails = (option, formik) => {
        let esPagoTarjeta = option.label.includes('TARJETA');
        let esPagoGiftcard = option.label.includes('GIFT');
        setPagoDetails({ esPagoTarjeta: esPagoTarjeta, esPagoGiftcard: esPagoGiftcard });

        if (!esPagoTarjeta) { formik.setFieldValue('pago.numeroTarjeta', "0000-0000-0000-0000"); }
        if (!esPagoGiftcard) {
            formik.setFieldValue('pago.montoGiftcard', 0);
            calculateMontoTotal(formik.values.pago, formik);
        }

    }

    return (
        <div>
            <Formik
                initialValues={getInitialValues()}
                validationSchema={validateSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {
                    (formik) => (
                        <Form>
                            <div className="mb-2">
                                Total: <b>Bs. {pagos.preItem.total}</b>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="" className="form-label">
                                    Método de pago
                                </label>
                                <Select
                                    className="w-100"
                                    showSearch
                                    optionFilterProp="children"
                                    options={pagos.resources.metodosPago.map(mp => ({
                                        label: mp.descripcion,
                                        value: mp.codigo_clasificador,
                                        item: mp
                                    }))}
                                    value={formik.values.pago.codigoMetodoPago}
                                    onChange={(value, option) => {
                                        formik.setFieldValue('pago.codigoMetodoPago', value);
                                        handlePagoDetails(option, formik);
                                    }}

                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                ></Select>
                            </div>
                            {
                                (pagoDetails.esPagoTarjeta) ? (
                                    <div className="mb-2">
                                        <label htmlFor="" className="form-label">
                                            Nro. de Tarjeta
                                        </label>
                                        <IMaskInput
                                            className="form-control form-control-sm"
                                            mask={"0000-XXXX-XXXX-0000"}
                                            value={formik.values.pago.numeroTarjeta}
                                            unmask={false}
                                            overwrite={true}
                                            onAccept={(value, mask) => formik.setFieldValue('pago.numeroTarjeta', value)}
                                        />
                                    </div>
                                ) : null
                            }

                            <div className="mb-2">
                                <label htmlFor="descuento">
                                    Descuento
                                </label>
                                <input type="number" step="any" min={0} className="form-control form-control-sm"
                                    value={formik.values.pago.descuento}
                                    onFocus={(e) => e.target.select()}
                                    onChange={(e) => {
                                        let desc = (e.target.value) ? parseFloat(e.target.value) : 0;
                                        formik.setFieldValue('pago.descuento', desc);
                                        calculateMontoTotal({...formik.values.pago, descuento: desc}, formik);
                                    }}
                                />
                                <ErrorMessage name="pago.descuento" element={FormError} />
                            </div>
                            {
                                (pagoDetails.esPagoGiftcard) ? (
                                    <div className="mb-2">
                                        <label htmlFor="giftcard">
                                            Monto Giftcard
                                        </label>
                                        <input type="number" step="any" min={0} className="form-control form-control-sm"
                                            value={formik.values.pago.montoGiftcard}
                                            onChange={(e) => {
                                                let giftcard = e.target.value ? parseFloat(e.target.value) : 0;
                                                formik.setFieldValue('pago.montoGiftcard', giftcard);
                                                calculateMontoTotal({...formik.values.pago, montoGiftcard: giftcard}, formik);
                                            }}
                                        />
                                    </div>
                                ) : null
                            }

                            <div className="mb-2">
                                <label htmlFor="pagar">
                                    Monto a Pagar
                                </label>
                                <input type="text" className="form-control form-control-sm"
                                    value={formik.values.pago.montoAPagar}
                                    disabled
                                />
                                <ErrorMessage name="pago.montoAPagar" component={FormError} />
                            </div>

                            <div>
                                <label htmlFor="pagar">
                                    Moneda:
                                </label>
                                <Select
                                    className="w-100"
                                    options={pagos.monedas.map(mon => ({
                                        label: mon.descripcion,
                                        value: mon.cod_tipo_moneda
                                    }))}
                                    value={formik.values.pago.codMoneda}
                                    onChange={(value) => {
                                        formik.setFieldValue("pago.codMoneda", value);
                                        setCambioMoneda(formik.values.pago.montoAPagar, value, formik);
                                    }}

                                ></Select>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="pagar">
                                    Monto total moneda
                                </label>
                                <input type="text" className="form-control form-control-sm"
                                    value={formik.values.pago.montoTotalMoneda}
                                    disabled
                                />
                            </div>

                            {/* <div className="mb-2">
                                <div>
                                    <span className="me-2">GenerateActive</span>
                                    <input type="checkbox" name="" id=""
                                        value={formik.values.cafc_generate.isActive}
                                        onChange={(e) => formik.setFieldValue('cafc_generate.isActive', e.target.checked)}
                                    />
                                </div>
                            </div> */}

                            {
                                (formik.values.cafc_generate.isActive) ? (
                                    <div className="row mb-2">
                                        <div className="col-6">
                                            <input type="number" className="form-control form-control-sm"
                                                value={formik.values.cafc_generate.cod_cufd_id}
                                                onChange={(e) => formik.setFieldValue('cafc_generate.cod_cufd_id', e.target.value)}
                                                placeholder="cod_cufd_id"
                                            />
                                        </div>
                                        <div className="col-6">
                                            <input type="datetime-local" name="" id="" className="form-control form-control-sm"
                                                value={formik.values.cafc_generate.fecha}
                                                onChange={(e) => formik.setFieldValue('cafc_generate.fecha', e.target.value)}
                                                placeholder="fecha"
                                            />
                                        </div>
                                    </div>
                                ) : ""
                            }

                            <div className="mb-">
                                <button className="btn btn-sm btn-green w-100" type="submit"
                                    disabled={pagos.loading}
                                >
                                    {
                                        (pagos.loading) ? (<>Registrando...</>)
                                            : <>Facturar e imprimir</>
                                    }
                                </button>
                            </div>

                        </Form>
                    )
                }
            </Formik>


        </div>
    );
}

export const ModalPagos = ({after_submit}) => {
    const { pagos } = useContext(EditableContext);

    return (
        <Modal
            open={pagos.pagosModalVisibility}
            title="Pago"
            onCancel={pagos.hidePagosModal}
            footer={null}
        >
            <Pagos after_submit={after_submit}/>
        </Modal>
    );

}

require('./bootstrap');

import "./components/admin/admins/Main";
import "./components/admin/tiendas/Main";

import "./components/clientes/token_api/Main";
import "./components/clientes/usuarios/Main";
import "./components/clientes/productos/Main";
import "./components/clientes/clientes/Main";
import "./components/clientes/tienda_pv/Main";
import "./components/clientes/ventas/Main";
import "./components/clientes/ventas/MainUsuario";
import "./components/clientes/cafc/Main";

import { connector } from "../../common/connector";

export const defaultItem = {
    id: 0,
    codigo: "",
    medida: "",
    cod_act_eco: "",
    cod_prod_sin: "",
    tipo: "P",
    descripcion: "",
    precio: 0,
}

export const reducer = (state, action) => {
    switch (action.type) {
        case 'FILL':
            return action.payload;

        case 'ADD':
            return [...state, action.payload];
        
        case 'UPDATE':
            return state.map(it => {
                if(it.id === action.payload.id) { return action.payload; }
                else { return it; }
            });
        
        case 'REMOVE':
            return state.filter(it => it.id != action.payload);
        
        default: return state;
    }
}

export const getItems = (params, signal = null) => {
    return connector.get('api/productos', { params: params, signal: signal});
}

export const createItem = (data) => {
    return connector.post('api/productos', data);
}

export const updateItem = (data) => {
    return connector.put(`api/productos/${data.id}`, data)
}

export const deleteItem = (id) => {
    return connector.delete(`api/productos/${id}`);
}

export const getResources = () => {
    return connector.get(`api/productos/resources`);
}

export const setDescuento = (data) => {
    return connector.post(`api/productos/set_descuento`, data);
}

export const delDescuento = (producto_id) => {
    return connector.post(`api/productos/del_descuento`, {producto_id});
}
import { Collapse, Table } from "antd";
import { useState, useEffect, useRef } from "react";
const { Column } = Table;
import { sprintf } from "sprintf-js";

const TableComponent = ({
    items, isLoading, tableParams, onTableChange, updateItem, deleteItem
}) => {
    const [itemsList, setItemsList] = useState(items);
    const [loading, setLoading] = useState(isLoading);
    const [params, setParams] = useState(tableParams);
    const searchInput = useRef(null);

    useEffect(() => {
        setItemsList(items);
    }, [items]);

    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        setParams(tableParams);
    }, [tableParams]);

    const ActionButtons = ({ item }) => {
        return (
            <>
                <button className="btn btn-sm btn-action btn-light border py-0" title="Editar"
                    onClick={() => updateItem(item)}>
                    <span className="fas fa-pen text-sm" style={{ fontSize: '0.85em' }}></span>
                </button>
                {
                    (!item.master) ? (
                        <button className="btn btn-sm btn-action btn-light border py-0 ms-1" title="Eliminar"
                            onClick={() => deleteItem(item.id)}>
                            <span className="fas fa-trash text-sm" style={{ fontSize: '0.85em' }}></span>
                        </button>
                    ) : ""
                }

            </>
        );
    }

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div className="p-1" onKeyDown={e => e.stopPropagation()}>
                <input type="text" className="form-control form-control-sm"
                    ref={searchInput}
                    placeholder='Escribe algo...'
                    value={(selectedKeys[0]) ? selectedKeys[0] : ""}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') { handleSearch(selectedKeys, confirm, dataIndex) }
                    }}
                />
                <div className="mt-1 d-flex justify-content-center">
                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    >
                        <span className="fas fa-search"></span>
                    </button>

                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => clearFilters && handleReset(clearFilters, setSelectedKeys, confirm)}
                    >
                        Reset
                    </button>

                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => { close(); }}
                    >
                        Salir
                    </button>
                </div>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) { setTimeout(() => searchInput.current?.select(), 100); }
        }
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };
    const handleReset = (clearFilters, setSelectedKeys, confirm) => {
        clearFilters();
        setSelectedKeys([]);
        confirm();
    };

    return (
        <Table
            size="small"
            dataSource={itemsList}
            rowKey='id'
            loading={loading}
            onChange={onTableChange}
            pagination={{ ...params.pagination, position: ['bottomCenter'], showSizeChanger: true }}
            scroll={{ y: 400, }}
            bordered

        >
            <Column title="*" dataIndex="id" key="buttons" width={90} className="text-center"
                render={(id, row) => (<ActionButtons item={row} />)}

            />
            <Column title="ID" dataIndex="id" key="id" align="center" className="fw-bold"
                sorter={true}
                width={100}
                render={(value) => sprintf('%04d', value)}
            />
            <Column title="Nit" dataIndex="nit" key="nit"
                sorter={true}
                width={100}
                filterDropdown={getColumnSearchProps('nit').filterDropdown}
                onFilterDropdownOpenChange={getColumnSearchProps('nombre').onFilterDropdownOpenChange}
            />
            <Column title="Razón Social" dataIndex="razon_social" key="razon_social"
                sorter={true}
                width={200}
                filterDropdown={getColumnSearchProps('razon_social').filterDropdown}
                onFilterDropdownOpenChange={getColumnSearchProps('razon_social').onFilterDropdownOpenChange}
            />
            <Column title="Municipio" dataIndex="municipio" key="municipio"
                sorter={true}
                width={200}
                filterDropdown={getColumnSearchProps('municipio').filterDropdown}
                onFilterDropdownOpenChange={getColumnSearchProps('municipio').onFilterDropdownOpenChange}
            />
            <Column title="Teléfono" dataIndex="telefono" key="telefono"
                sorter={true}
                width={200}
                filterDropdown={getColumnSearchProps('telefono').filterDropdown}
                onFilterDropdownOpenChange={getColumnSearchProps('telefono').onFilterDropdownOpenChange}
            />
            <Column title="Dirección" dataIndex="direccion" key="direccion"
                sorter={true}
                width={250}
                filterDropdown={getColumnSearchProps('direccion').filterDropdown}
                onFilterDropdownOpenChange={getColumnSearchProps('direccion').onFilterDropdownOpenChange}
            />

            <Column title="Usuario-Email" dataIndex="t_admin" key="t_admin.email"
                render={(value, row) => (<>{value.email}</>)}
                width={200}
                filterDropdown={getColumnSearchProps('t_admin.email').filterDropdown}
                onFilterDropdownOpenChange={getColumnSearchProps('t_admin.email').onFilterDropdownOpenChange}
            />

        </Table>
    );
}

export default TableComponent;
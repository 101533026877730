import { Modal } from "antd";
import { Children, useState } from "react";

const AnularFactura = ({children , motivosAnulacion, destroyItem}) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        venta_id: 0,
        cod_motivo: null,
    });

    const show = (data) => {
        setData(prev => ({...prev, venta_id: data}));
        setVisible(true);
    }

    const hide = () => {
        setVisible(false);
    }

    const handle_submit = async (e) => {
        e.preventDefault();

        setLoading(true);
        await destroyItem(data);

        setData({ venta_id: 0, cod_motivo: null, });
        setLoading(false);
        setVisible(false);
    }

    return (
        <Modal
            title="Anular Factura"
            open={visible}
            onCancel={hide}
            footer={null}
            scroll={{ y: 400, }}

        >
            {children({show,hide})}
            <div>
                <form action="" onSubmit={handle_submit}>
                    <fieldset disabled={loading}>
                        <div className="mb-2">
                            <label htmlFor="" className="form-label">
                                Motivo
                            </label>
                            <select name="motivo_anulacion" id="motivo_anulacion"
                                className="form-select form-select-sm"
                                value={data.cod_motivo??""}
                                onChange={(e) => setData(prev => ({...prev, cod_motivo: e.target.value}))}
                                required
                            >
                                <option value="">- Seleccionar -</option>
                                {
                                    motivosAnulacion.map(ma => (
                                        <option key={ma.codigo_clasificador} value={ma.codigo_clasificador}>{ma.descripcion}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="mb-2 text-end">
                            <button type="submit" className="btn btn-sm btn-danger">
                                { (loading)? "Anulando...": "Anular"}
                            </button>
                        </div>
                    </fieldset>
                </form>
            </div>
        </Modal>
    );
}

export default AnularFactura;
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState, useContext } from "react";
import * as Yup from "yup";
import { Spin } from 'antd';
import FormError from "../../common/FormError";
import { EditableContext } from "../../common/EditableContextProvider";
import { App } from "antd";

const FormComponent = ({ errors }) => {
    const { modal } = App.useApp();
    const { editItem, onFormAction } = useContext(EditableContext);
    const [initialValues, setInitialvalues] = useState(editItem);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setInitialvalues(editItem);
    }, [editItem]);

    const handleSubmit = (values, formik) => {
        modal.confirm({
            title: "Confirmar", content: "¿Guardar datos?",
            onOk: () => { 
                try {
                    setLoading(true);
                    onFormAction(values, formik);
                    setInitialvalues(values);
                } catch (e) {
                    formik.resetForm();
                    if (e.code === "REQ_FAIL") { console.log(e); }
                }
                finally { setLoading(false); }
            },
            onCancel: () => { formik.resetForm(); }
        });
    }

    const validateSchema = Yup.object({
        codigo: Yup.string()
            .max(500, 'El texto es demasiado largo')
            .required('El campo nombre es obligario.'),
        fecha_inicio: Yup.date()
            .required('El campo es obligario.'),
        fecha_fin: Yup.date()
            .required('El campo es obligario.')
    });

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validateSchema}
                enableReinitialize={true}
            >
                {
                    (formik) => (
                        <Form>
                            <fieldset className="border rounded p-2 mb-2 mt-2" disabled={loading} >
                                <div className="pb-1 fw-bold">Información de Tienda</div>
                                <div className="mb-2">
                                    <label htmlFor="codigo" className="form-label">
                                        CÓDIGO:
                                        <span className="text-danger">(*)</span>
                                    </label>
                                    <textarea name="codigo" rows="3"
                                        className="form-control form-control-sm"
                                        value={formik.values.codigo ?? ""}
                                        onChange={(e) => formik.setFieldValue('codigo', e.target.value)}
                                        onBlur={() => formik.setFieldTouched('codigo')}
                                    ></textarea>
                                    <ErrorMessage name="codigo" component={FormError} />
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md-3">
                                        <label htmlFor="fecha_inicio" className="form-label">
                                            Fecha Inicio
                                            <span className="text-danger">(*)</span>
                                        </label>
                                        <input type="date"
                                            className="form-control form-control-sm text-uppercase"
                                            value={formik.values.fecha_inicio ?? ""}
                                            onChange={(e) => { formik.setFieldValue('fecha_inicio', e.target.value) }}
                                            onBlur={() => formik.setFieldTouched('fecha_inicio')}
                                        />
                                        <ErrorMessage name="fecha_inicio" component={FormError} />
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="fecha_fin" className="form-label">
                                            Fecha fin
                                            <span className="text-danger">(*)</span>
                                        </label>
                                        <input type="date"
                                            className="form-control form-control-sm text-uppercase"
                                            value={formik.values.fecha_fin ?? ""}
                                            onChange={(e) => { formik.setFieldValue('fecha_fin', e.target.value) }}
                                            onBlur={() => formik.setFieldTouched('fecha_fin')}
                                        />
                                        <ErrorMessage name="fecha_fin" component={FormError} />
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="p-2" disabled={loading}>
                                <div className="mb-2 d-flex justify-content-between">
                                    <div>
                                        <span className="text-danger">(*) </span>
                                        <u>Campo obligatorio.</u>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <button type="submit" className="btn btn-sm btn-light border border-secondary px-4">
                                            {
                                                (loading) ? (
                                                    <Spin size="small" />
                                                ) : "Guardar"
                                            }
                                        </button>
                                    </div>
                                </div>
                            </fieldset>
                        </Form>
                    )
                }
            </Formik>
        </div>
    );
}

export default FormComponent;
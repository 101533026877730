import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState, useContext } from "react";
import * as Yup from "yup";
import { Select, Spin } from 'antd';
import FormError from "../../common/FormError";
import { EditableContext } from "../../common/EditableContextProvider";

const FormComponent = ({ errors }) => {
    const { editItem, onFormAction, resources } = useContext(EditableContext);
    const [initialValues, setInitialvalues] = useState(editItem);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setInitialvalues(editItem);
    }, [editItem]);

    const handleSubmit = async (values, formik) => {
        try {
            setLoading(true);
            await onFormAction(values);
            setInitialvalues(values);
        } catch (e) {
            if (e.code === "REQ_FAIL") {
                if (e.errors.email) { formik.setFieldError("email", e.errors.email[0]); }
            }
        }
        finally { setLoading(false); }
    }

    const validateSchema = Yup.object({
        razon_social: Yup.string()
            .max(200, 'El texto es demasiado largo')
            .required('El campo es obligario.'),
        cod_tipo_doc: Yup.string()
            .required('El campo es obligario.'),
        nodoc: Yup.string()
            .max(100, 'El texto es demasiado largo')
            .required('El campo es obligario.'),
        complemento: Yup.string()
            .max(20, 'El texto es demasiado largo')
            .nullable(),
        email: Yup.string()
            .max(200, 'El texto es demasiado largo')
            .nullable(),
    });

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validateSchema}
                enableReinitialize={true}
            >
                {
                    (formik) => (
                        <Form disabled={loading}>
                            <div className="py-1">
                                <span>ID: </span>
                                <span className="fw-bold ms-1">{sprintf('%04d', editItem.id)}</span>
                            </div>
                            <div className="mb-2">
                                <div className="mb-2">
                                    <label htmlFor="descripcion" className="form-label">
                                        Razón Social
                                        <span className="text-danger">(*)</span>
                                    </label>
                                    <Field id="razon_social" name="razon_social" type="text" className="form-control form-control-sm" />
                                    <ErrorMessage name="razon_social" component={FormError} />
                                </div>
                            </div>

                            <div className="mb-2">
                                <label htmlFor="cod_tipo_doc" className="form-label">
                                    Tipo de documento
                                    <span className="text-danger">(*)</span>
                                </label>
                                <Select
                                    className="w-100"
                                    options={resources.tipos_doc?.map(td => {
                                        return { label: td.descripcion, value: td.codigo_clasificador };
                                    })}
                                    value={formik.values.cod_tipo_doc?.toString()}
                                    onChange={(value, option) => {
                                        formik.setFieldValue('cod_tipo_doc', value);
                                        formik.setFieldValue('tipo_doc', option.label);
                                    }}
                                ></Select>
                                <ErrorMessage name="cod_tipo_doc" component={FormError} />
                            </div>

                            <div className="row mb-2">
                                <div className="col-md">
                                    <label htmlFor="nodoc" className="form-label">
                                        Nro. de Documento
                                        <span className="text-danger">(*)</span>
                                    </label>
                                    <Field id="nodoc" name="nodoc" type="text"
                                        className="form-control form-control-sm" />
                                    <ErrorMessage name="nodoc" component={FormError} />
                                </div>
                                <div className="col-md">
                                    <label htmlFor="complemento" className="form-label">
                                        Complemento
                                    </label>
                                    <input id="complemento" name="complemento" type="text"
                                        className="form-control form-control-sm" 
                                        value={formik.values.complemento??""} 
                                        onChange={(e) => formik.setFieldValue('complemento',e.target.value)}
                                    />
                                    <ErrorMessage name="complemento" component={FormError} />
                                </div>
                            </div>

                            <div className="mb-2">
                                <label htmlFor="email" className="form-label">
                                    Email
                                    <span className="text-danger">(*)</span>
                                </label>
                                <Field id="email" name="email" type="email"
                                    className="form-control form-control-sm" />
                                <ErrorMessage name="email" component={FormError} />
                            </div>

                            <div className="mb-2 d-flex justify-content-between">
                                <div>
                                    <span className="text-danger">(*) </span>
                                    <u>Campo obligatorio.</u>
                                </div>
                                <button type="submit" className="btn btn-sm btn-light border border-secondary px-4">
                                    {
                                        (loading) ? (
                                            <Spin size="small" />
                                        ) : "Guardar"
                                    }
                                </button>
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </div>
    );
}

export default FormComponent;
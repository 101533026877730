import React from "react";
import { createRoot } from 'react-dom/client';
import ErrorBox from "../../common/ErrorBox";
import TableComponent from "./TableComponent";
import { useState, useEffect } from "react";
import { ConfigProvider, Modal, App } from 'antd';
import { EditableContextProvider } from "../../common/EditableContextProvider";
import FormComponent from "./FormComponent";
import { customTheme } from "../../common/customTheme";
import useAdmins from "./useAdmins";

export default function Main() {
    const { editItem, fillItems, items, selectItem, newItem, saveItem, destroyItem, loading, tableParams } = useAdmins();
    const [formVisible, setFormVisible] = useState(false);
    const { modal, notification } = App.useApp();

    useEffect(() => {
        fillItems();
    }, []);

    const handleTableChange = (pagination, filters, sorter, extra) => {
        let params = {
            ...tableParams,
            pagination,
            filters,
            sorter,
        };
        fillItems(params);
    }
    const handleFormAction = async (item) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (item === editItem) { notification.info({ description: "No hay cambios." }); resolve(); return; }
                await saveItem(item);
                notification.success({ description: "¡Guardado correcto!" });
                resolve();
            } catch (e) {
                if(e.code === "SERVER_ERROR") {
                    notification.error({ description: e.message });
                }
                reject(e);
            }
        });
    }
    const handleDeleteItem = (id) => {
        modal.confirm({
            content: `¿Eliminar ítem (ID: ${id}) ?`,
            onOk: async () => {
                try {
                    await destroyItem(id);
                    notification.info({ message: "¡Item eliminado!" });
                } catch ({ response }) {
                    notification.error({ message: "Ocurrió un error al eliminar el ítem." });
                    console.log(response.data);
                }
            }
        });
    }
    const addNewItem = () => { newItem(); showForm(); }
    const setUpdateItem = (item) => { selectItem(item); showForm(); }
    const showForm = () => { setFormVisible(true); }
    const hideForm = () => { setFormVisible(false); }

    return (
        <div>
            <div className='my-1 py-1'>
                <button className="btn btn-sm btn-light border"
                    onClick={() => { addNewItem(); }}
                >
                    <i className="fa-solid fa-plus me-1"></i>
                    Agregar
                </button>
            </div>
            <TableComponent
                items={items}
                isLoading={loading}
                tableParams={tableParams}
                onTableChange={handleTableChange}
                updateItem={setUpdateItem}
                deleteItem={handleDeleteItem}
            />

            <Modal title="Formulario"
                open={formVisible}
                onCancel={hideForm}
                destroyOnClose={true}
                footer={null}
            >
                <EditableContextProvider data={{
                    editItem: editItem,
                    onFormAction: handleFormAction
                }}
                >
                    <FormComponent />
                </EditableContextProvider>
            </Modal>
        </div>
    );
}

let main = document.getElementById('admins-main');
if (main) {
    const root = createRoot(main);
    root.render(
        <React.StrictMode>
            <ErrorBox>
                <ConfigProvider theme={customTheme}>
                    <App>
                        <Main />
                    </App>
                </ConfigProvider>
            </ErrorBox>
        </React.StrictMode>
    );
}

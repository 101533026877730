import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState, useContext } from "react";
import * as Yup from "yup";
import { Select, Spin } from 'antd';
import FormError from "../../common/FormError";
import { EditableContext } from "../../common/EditableContextProvider";

const FormComponent = ({ errors }) => {
    const { editItem, onFormAction, resources } = useContext(EditableContext);
    const [initialValues, setInitialvalues] = useState(editItem);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setInitialvalues(editItem);
    }, [editItem]);

    const handleSubmit = async (values, formik) => {
        try {
            setLoading(true);
            await onFormAction(values);
            setInitialvalues(values);
        } catch (e) {
            if (e.code === "REQ_FAIL") {
                if (e.errors.email) { formik.setFieldError("email", e.errors.email[0]); }
            }
        }
        finally { setLoading(false); }
    }

    const validateSchema = Yup.object({
        codigo: Yup.string()
            .max(50, 'El texto es demasiado largo')
            .required('El campo es obligario.'),
        medida: Yup.string()
            .required('El campo es obligario.'),
        cod_act_eco: Yup.string()
            .required('El campo es obligario.'),
        cod_prod_sin: Yup.string()
            .required('El campo es obligario.'),
        tipo: Yup.string()
            .required('El campo es obligario.'),
        descripcion: Yup.string()
            .max(200, 'El texto es demasiado largo'),
        precio: Yup.number()
            .required('El campo es obligario.')
    });

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validateSchema}
                enableReinitialize={true}
            >
                {
                    (formik) => (
                        <Form disabled={loading}>
                            <div className="py-1">
                                <span>ID: </span>
                                <span className="fw-bold ms-1">{sprintf('%04d', editItem.id)}</span>
                            </div>
                            <div className="mb-2">

                            </div>
                            <div className="row mb-2">
                                <div className="col-md">
                                    <label htmlFor="codigo" className="form-label">
                                        Código
                                        <span className="text-danger">(*)</span>
                                    </label>
                                    <Field id="codigo" name="codigo" type="text" className="form-control form-control-sm" />
                                    <ErrorMessage name="codigo" component={FormError} />
                                </div>
                                <div className="col-md">
                                    <label htmlFor="tipo" className="form-label">
                                        Tipo
                                        <span className="text-danger">(*)</span>
                                    </label>
                                    <Select
                                        className="w-100"
                                        name="tipo" id="tipo"
                                        options={[
                                            { label: "Producto", value: "P" },
                                            { label: "Servicio", value: "S" },
                                        ]}
                                        value={formik.values.tipo}
                                        onChange={(value) => {
                                            formik.setFieldValue('tipo', value);
                                            formik.setFieldValue('cod_act_eco', null);
                                            formik.setFieldValue('cod_prod_sin', null);
                                        }}
                                    ></Select>
                                    <ErrorMessage name="tipo" component={FormError} />
                                </div>
                            </div>

                            <div className="mb-2">
                                <label htmlFor="u_medida" className="form-label">
                                    Unidad de medida
                                    <span className="text-danger">(*)</span>
                                </label>
                                <Select
                                    className="w-100"
                                    name="u_medida" id="u_medida"
                                    showSearch
                                    filterOption={(input, option) => (option?.label ?? '').toUpperCase().includes(input.toUpperCase())}
                                    options={resources.u_medidas?.map(um => {
                                        return { label: um.descripcion, value: um.codigo_clasificador }
                                    })}
                                    value={formik.values.medida}
                                    onChange={(value) => formik.setFieldValue('medida', value)}
                                ></Select>
                                <ErrorMessage name="u_medida" component={FormError} />
                            </div>

                            <div className="mb-2">
                                <label htmlFor="cod_act_eco" className="form-label">
                                    Código de Actividad Económica
                                    <span className="text-danger">(*)</span>
                                </label>
                                <Select
                                    className="w-100"
                                    name="cod_act_eco" id="cod_act_eco"
                                    options={resources.act_economicas?.filter(ae => ae.tipo_actividad === formik.values.tipo).map(ae => {
                                        return { label: ae.descripcion, value: ae.codigo_caeb }
                                    })}
                                    value={formik.values.cod_act_eco}
                                    onChange={(value) => {
                                        formik.setFieldValue('cod_act_eco', value);
                                    }}
                                ></Select>
                                <ErrorMessage name="cod_act_eco" component={FormError} />
                            </div>

                            <div className="mb-2">
                                <label htmlFor="cod_prod_sin" className="form-label">
                                    Código de Producto SIN
                                    <span className="text-danger">(*)</span>
                                </label>
                                <Select
                                    className="w-100"
                                    name="cod_prod_sin" id="cod_prod_sin"
                                    options={resources.prod_serv?.filter(ps => ps.codigo_actividad === formik.values.cod_act_eco).map(ps => {
                                        return { label: ps.descripcion, value: ps.codigo_producto }
                                    })}
                                    value={formik.values.cod_prod_sin}
                                    onChange={(value) => formik.setFieldValue('cod_prod_sin', value)}
                                ></Select>
                                <ErrorMessage name="cod_prod_sin" component={FormError} />
                            </div>

                            <div className="mb-2">
                                <label htmlFor="descripcion" className="form-label">
                                    Descripción
                                    <span className="text-danger">(*)</span>
                                </label>
                                <Field id="descripcion" name="descripcion" as="textarea" className="form-control form-control-sm" />
                                <ErrorMessage name="descripcion" component={FormError} />
                            </div>

                            <div className="mb-2">
                                <label htmlFor="precio" className="form-label">
                                    Precio en Bs.
                                    <span className="text-danger">(*)</span>
                                </label>
                                <Field id="precio" name="precio" type="number" step="any" min="0" max="23000000" className="form-control form-control-sm" />
                                <ErrorMessage name="precio" component={FormError} />
                            </div>

                            <div className="mb-2 d-flex justify-content-between">
                                <div>
                                    <span className="text-danger">(*) </span>
                                    <u>Campo obligatorio.</u>
                                </div>
                                <button type="submit" className="btn btn-sm btn-light border border-secondary px-4">
                                    {
                                        (loading) ? (
                                            <Spin size="small" />
                                        ) : "Guardar"
                                    }
                                </button>
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </div>
    );
}

export default FormComponent;
import { Table } from "antd";
import { useState, useEffect, useRef } from "react";
const { Column } = Table;
import { sprintf } from "sprintf-js";

const TableComponent = ({
    items, isLoading, tableParams, onTableChange, updateItem,
    deleteItem, showItems, revertir, puntosVenta
}) => {
    const [itemsList, setItemsList] = useState(items);
    const [loading, setLoading] = useState(isLoading);
    const [params, setParams] = useState(tableParams);
    const searchInput = useRef(null);

    useEffect(() => {
        setItemsList(items);
    }, [items]);

    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        setParams(tableParams);
    }, [tableParams]);

    const ActionButtons = ({ item }) => {
        return (
            <>
                {
                    (item.estado_factura === "VALIDADA") ?
                        (
                            <button className="btn btn-sm btn-action btn-danger py-0" title="Anular"
                                onClick={() => deleteItem(item.id)}
                            >
                                <span className="fas fa-trash text-sm" style={{ fontSize: '0.85em' }}></span>
                            </button>
                        ) : (<></>)
                }
                {
                    (item.estado_factura === "ANULADA") ?
                        (
                            <button className="btn btn-sm btn-action btn-danger py-0" title="Revertir"
                                onClick={() => revertir(item.id)}
                            >
                                <span className="fas fa-rotate-left text-sm" style={{ fontSize: '0.85em' }}></span>
                            </button>
                        ) : (<></>)
                }
                <button className="btn btn-sm btn-action btn-light border py-0 ms-1" title="Ver"
                    onClick={() => showItems(item)}
                >
                    <span className="fas fa-table text-sm" style={{ fontSize: '0.85em' }}></span>
                </button>
            </>
        );
    }

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div className="p-1" onKeyDown={e => e.stopPropagation()}>
                <input type="text" className="form-control form-control-sm"
                    ref={searchInput}
                    placeholder='Escribe algo...'
                    value={(selectedKeys[0]) ? selectedKeys[0] : ""}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') { handleSearch(selectedKeys, confirm, dataIndex) }
                    }}
                />
                <div className="mt-1 d-flex justify-content-center">
                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    >
                        <span className="fas fa-search"></span>
                    </button>

                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => clearFilters && handleReset(clearFilters, setSelectedKeys, confirm)}
                    >
                        Reset
                    </button>

                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => { close(); }}
                    >
                        Salir
                    </button>
                </div>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) { setTimeout(() => searchInput.current?.select(), 100); }
        }
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };
    const handleReset = (clearFilters, setSelectedKeys, confirm) => {
        clearFilters();
        setSelectedKeys([]);
        confirm();
    };

    return (
        <Table
            size="small"
            dataSource={itemsList}
            rowKey='id'
            loading={loading}
            onChange={onTableChange}
            pagination={{ ...params.pagination, position: ['bottomCenter'], showSizeChanger: true }}
            scroll={{ x: 'max-content' }}
            bordered
        >
            <Column title="*" dataIndex="id" key="buttons" width={90} className="text-center"
                render={(id, row) => (<ActionButtons item={row} />)}
                fixed="left"
            />
            <Column title="ID" dataIndex="id" key="id" align="center" className="fw-bold"
                sorter={true}
                width={100}
                render={(value) => sprintf('%04d', value)}
            />
            <Column title="Fecha" dataIndex="fecha" key="fecha"
                sorter={true}
                width={150}
            />
            <Column title="PuntoVenta" dataIndex="tienda_pv" key="tienda_pv"
                sorter={true}
                width={150}
                render={(value) => (<>{value.nombre}</>)}
                /* filterDropdown={getColumnSearchProps('tienda_pv').filterDropdown}
                onFilterDropdownOpenChange={getColumnSearchProps('tienda_pv').onFilterDropdownOpenChange} */

                filters={puntosVenta.map(pv => ({text:pv.nombre, value: pv.nombre}))}
            />
            <Column title="Cliente" dataIndex="cliente" key="cliente"
                width={200}
                sorter={true}
                filterDropdown={getColumnSearchProps('codigo').filterDropdown}
                onFilterDropdownOpenChange={getColumnSearchProps('codigo').onFilterDropdownOpenChange}
                render={(value, row) => (<>{row.cliente.razon_social}</>)}
            />
            <Column title="Total" dataIndex="total" key="total"
                width={150}
            />
            <Column title="Estado" dataIndex="estado_factura" key="estado_factura"
                width={100}
                align="center"
                filters={[
                    {text:"VALIDADA", value: "VALIDADA"},
                    {text:"ANULADA", value: "ANULADA"},
                    {text:"REVERTIDA", value: "REVERTIDA"},
                ]}
            />
            <Column title="Ver Factura" dataIndex="estado_factura" key="ver_factura"
                sorter={true}
                width={100}
                align="center"
                render={(value, item) => {
                    switch (value) {
                        case 'R': return (<>SIN FACTURAR</>);
                        case 'REVERTIDA':
                        case 'ANULADA':
                        case 'VALIDADA': return (
                            <>
                                <a className="btn btn-sm border btn-light"
                                    href={`/factura/pdf/${item.id}`}
                                    target="_blank"
                                >
                                    <i className="fas fa-print"></i>
                                </a>
                                <a className="btn btn-sm btn-outline-primary ms-1"
                                    href={`https://siat.impuestos.gob.bo/consulta/QR?nit=${item.tienda.nit}&cuf=${item.factura?.cuf}&numero=${item.factura?.id}`}
                                    target="_blank"
                                >
                                    Siat
                                </a>
                            </>
                        );
                        default: return (<>{value}</>)
                    }
                }}
            />
            <Column title="Usuario" dataIndex="usuario" key="usuario"
                sorter={true}
                width={200}
                render={(value, row) => (<>{row.usuario?.nombre}</>)}
            />
        </Table>
    );
}

export default TableComponent;

import { Select, Table, Modal } from "antd";
import { EditableContext } from "../../common/EditableContextProvider";
import { useContext } from "react";
import { sprintf } from "sprintf-js";
import dayjs from "dayjs";
const { Column } = Table;
import { Formik, Form, ErrorMessage } from "formik";
import FormError from "../../common/FormError";
import * as Yup from 'yup';

export const TableEventos = () => {
    const contingencia = useContext(EditableContext);

    const handleFilter = (e) => {
        e.preventDefault();
        contingencia.fillEventos();
    }

    const showVentas = async (contingencia_id) => {
        contingencia.showModalVentasCafc(true);
        await contingencia.fillVentasCafc(contingencia_id);
    }

    return (
        <div>
            <form action="" onSubmit={handleFilter}>
                <div className="row gx-1 mb-2">
                    <div className="col-md-3">
                        <label htmlFor="tienda_pv_id">Punto de Venta</label>
                        <Select id="tienda_pv_id"
                            options={contingencia.resources.venta_puntos.map((pv) => ({
                                label: pv.nombre, value: pv.id
                            }))}
                            value={contingencia.eventosFilter.tienda_pv_id}
                            onChange={(value) => contingencia.setFilterParam('tienda_pv_id', value)}
                            required
                        ></Select>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="date_start">Fecha inicio</label>
                        <input type="date" name="date_start" id="date_start" className="form-control form-control-sm"
                            value={contingencia.eventosFilter.date_start}
                            onChange={(e) => contingencia.setFilterParam('date_start', e.target.value)}
                            required
                        />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="date_end">Fecha fin</label>
                        <input type="date" name="date_end" id="date_end" className="form-control form-control-sm"
                            value={contingencia.eventosFilter.date_end}
                            onChange={(e) => contingencia.setFilterParam('date_end', e.target.value)}
                            required
                        />
                    </div>
                    <div className="col-md-auto">
                        <label className="text-white w-100">·</label>
                        <button type="submit" className="btn btn-sm btn-light border">
                            Buscar...
                        </button>
                    </div>
                </div>
            </form>

            <Table
                size="small"
                dataSource={contingencia.eventos}
                rowKey="id"
                loading={contingencia.loadingEventos}
                bordered
            >
                <Column title="ID" dataIndex="id"
                    align="right"
                    render={(value) => (<>{sprintf("%04d", value)}</>)}
                    size={80}
                />
                <Column title="Periodo" dataIndex="date_start"
                    align="center"
                    render={(value, row) => (<>
                        <span className="fw-bold">{row.fecha_inicio}</span> <br />
                        / <span>{row.fecha_fin}</span>
                    </>)}
                    size={150}
                />
                <Column title="Cod_Recepción" dataIndex="cod_recepcion"
                    align="center"
                    size={80}
                />
                <Column title="Cod_Evento" dataIndex="cod_evento"
                    align="center"
                    size={50}
                />
                <Column title="·" dataIndex="id"
                    align="center"
                    size={50}
                    render={(value) => (
                        <button className="btn btn-sm btn-light border py-0"
                            onClick={(e) => { showVentas(value) }}>
                            Ver...
                        </button>
                    )}
                />

            </Table>

            <Modal
                title="Enviar Paquete Facturas"
                open={contingencia.modalVentasCafcVisible}
                onCancel={contingencia.hideModalVentasCafc}
                destroyOnClose={true}
                footer={null}
            >
                <div className="mb-3">
                    <Table
                        dataSource={contingencia.ventasCafc}
                        rowKey="id"
                        size="small"
                        loading={contingencia.ventasCafcLoading}
                        bordered
                    >
                        <Column title="ID" dataIndex="id" align="center"
                            size="80"
                            render={(value) => <>{sprintf("%04d", value)}</>}
                        />
                        <Column title="Fecha" dataIndex="fecha" align="center"
                            size="120"
                            render={(value) => <>{dayjs(value).format('DD-MM-YYYY')}</>}
                        />
                        <Column title="Cliente" dataIndex="cliente"
                            size="150"
                            render={(value) => <>{value.razon_social}</>}
                        />
                        <Column title="Total" dataIndex="total"
                            size="100"
                        />
                    </Table>
                </div>
                <div>
                    <Formik
                        initialValues={contingencia.initialCafcValues}
                        onSubmit={contingencia.submitCafc}
                        enableReinitialize={true}
                        validationSchema={Yup.object({
                            cafc: Yup.string()
                                .required('El campo es obligatorio.')
                        })}
                    >
                        {(formik) => (
                            <Form>
                                <fieldset disabled={contingencia.submitCafcLoading}>
                                    <div className="row gx-2">
                                        <div className="col-md">
                                            <div className="input-group input-group-sm">
                                                <span className="input-group-text" id="basic-addon1">
                                                    CAFC
                                                </span>
                                                <input type="text" className="form-control"
                                                    aria-label="Username" aria-describedby="basic-addon1"
                                                    value={formik.values.cafc}
                                                    onChange={(e) => formik.setFieldValue('cafc', e.target.value)}
                                                />
                                            </div>
                                            <ErrorMessage name="cafc" component={FormError} />
                                        </div>
                                        <div className="col-md-auto">
                                            <button type="submit" className="btn btn-sm btn-light border px-3">
                                                { (contingencia.submitCafcLoading)? "Enviando...":"Enviar Paquete"}
                                            </button>
                                        </div>
                                    </div>
                                </fieldset>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </div>
    );
}

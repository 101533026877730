import { useReducer, useState } from "react";
import { 
    getItems, createItem, updateItem, defaultItem, 
    deleteItem, reducer, getResources,
    setDescuento, delDescuento
} from "./functions";
import { App } from "antd";

const useProductos = () => {
    const {notification} = App.useApp();
    const [items, dispatch] = useReducer(reducer);
    const [editItem, setEditItem] = useState(defaultItem);
    const [loading, setLoading] = useState(true);
    const [tableParams, setTableParams] = useState({
        pagination: { "current": 1, "pageSize": 10, "total": 0 },
        filters: {},
        sorter: {},
    });
    const [resources, setResources] = useState({});

    const fillItems = async (params = tableParams, signal = new AbortController().signal) => {
        try {
            setLoading(true);
            let { data } = await getItems(params, signal);
            let { rows, total, current } = data;

            dispatch({ type: "FILL", payload: rows });
            setTableParams(prev => {
                return {
                    ...prev,
                    ...params,
                    pagination: {
                        ...prev.pagination,
                        ...params.pagination,
                        total: total,
                        current: current
                    }
                }
            });
        } catch (error) { console.log(error.message); }
        finally { setLoading(false); }
    }
    const newItem = () => { setEditItem(defaultItem); }

    const saveItem = async (item) => {
        let item_action = (item.id === 0) ? createItem(item) : updateItem(item);
        try {
            let res = await item_action;
            if (item.id === 0) { 
                item.id = res.data.id;
                /* dispatch({ type: "ADD", payload: item }); */
                fillItems();
            } else {
                dispatch({ type: "UPDATE", payload: item });
            }
            setEditItem(item);
            
        } catch ( error) {
            let { status, data } = error.response;
            if(status === 422) {
                let { errors } = data;
                throw { code: 'REQ_FAIL', message: 'Error en la validación.', errors: errors };
            } else {
                throw { code: 'SERVER_ERROR', message: 'Error interno.' };
            }

        } finally { setLoading(false); }
    }

    const selectItem = (item) => {
        setEditItem({...item, password: 'password', estado: Boolean(item.estado)});
    }

    const destroyItem = async (id) => {
        try {
            await deleteItem(id);
            fillItems();
        } catch (error) { console.log(error); }
    }

    const fillResources = async () => {
        try {
            setLoading(true);
            let { data } = await getResources();
            setResources(data);
        } catch (error) { throw new Error(error); }
    }

    const [loadingDescuento, setLoadingDescuento] = useState(false);

    const saveDescuento = async (values) => {
        try {

            if(values === editItem.descuento) { notification.info({ message: "No hay cambios." }); return; }
            setLoadingDescuento(true);
            let res = await setDescuento(values);
            setEditItem(prev => ({...prev, descuento: res.data}));
            fillItems();
            notification.success({
                message: "¡Guardado correcto!"
            });
            
        } catch (error) {
            
        } finally { setLoadingDescuento(false); }
    }

    const deleteDescuento = async () => {
        try {
            setLoadingDescuento(true);
            await delDescuento(editItem.id);

            setEditItem(prev => ({...prev, descuento: null}));
            fillItems();
            notification.success({
                message: "¡Guardado correcto!"
            });
        } catch (error) { } 
        finally { setLoadingDescuento(false); }
    }


    return {
        editItem,
        fillItems,
        items,
        selectItem,
        newItem,
        saveItem,
        destroyItem,
        loading,
        tableParams,
        resources,
        fillResources,
        saveDescuento,
        deleteDescuento,
        loadingDescuento
    };

}

export default useProductos;
import dayjs from "dayjs";
import { connector } from "../../common/connector";

export const defaultItem = {
    id: 0,
    nombre: "",
    descripcion: "",
    fecha_inicio: dayjs().format('YYYY-MM-DD'),
    fecha_fin: ""
}

export const reducer = (state, action) => {
    switch (action.type) {
        case 'FILL':
            return action.payload;

        case 'ADD':
            return [...state, action.payload];
        
        case 'UPDATE':
            return state.map(it => {
                if(it.id === action.payload.id) { return action.payload; }
                else { return it; }
            });
        
        case 'REMOVE':
            return state.filter(it => it.id != action.payload);
        
        default: return state;
    }
}

export const getItems = (params) => {
    return connector.get('api/tienda_pv', { params: params });
}

export const createItem = (data) => {
    return connector.post('api/tienda_pv', data);
}

export const updateItem = (data) => {
    return connector.put(`api/tienda_pv/${data.id}`, data)
}

export const deleteItem = (id) => {
    return connector.delete(`api/tienda_pv/${id}`);
}
import { connector } from "../../common/connector"

export const getItems = (data) => {
    return connector.get('api/ventas_cafc/contingencia', {
        params: data
    })
};

export const getResources = () => {
    return connector.get('api/ventas_cafc/resources');
}

export const registrarEvento = (data) => {
    return connector.post('api/ventas_cafc/registrar-evento', data);
}

export const getEventos = (eventosFilter) => {
    return connector.get('api/ventas_cafc/eventos', {
        params: eventosFilter
    });
}

export const getFacturasCafc = (contingencia_id) => {
    return connector.get(`api/ventas_cafc/ventas/${contingencia_id}`);
}

export const sendFormCafc = (values) => {
    return connector.post('api/ventas_cafc/register-data', values);
}

import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState, useContext } from "react";
import * as Yup from "yup";
import { Select, Spin } from 'antd';
import FormError from "../../common/FormError";
import { EditableContext } from "../../common/EditableContextProvider";

const FormComponent = ({ errors }) => {
    const { editItem, onFormAction, resources } = useContext(EditableContext);
    const [initialValues, setInitialvalues] = useState(editItem);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setInitialvalues(editItem);
    }, [editItem]);

    const handleSubmit = async (values, formik) => {
        try {
            setLoading(true);
            await onFormAction(values);
            setInitialvalues(values);
        } catch (e) {
            if (e.code === "REQ_FAIL") {
                if (e.errors.email) { formik.setFieldError("email", e.errors.email[0]); }
            }
        }
        finally { setLoading(false); }
    }

    const validateSchema = Yup.object({
        nombre: Yup.string()
            .max(200, 'El texto es demasiado largo')
            .required('El campo es obligario.'),
        descripcion: Yup.string()
            .max(200, 'El texto es demasiado largo')
            .nullable('nulleado'),
    });

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validateSchema}
                enableReinitialize={true}
            >
                {
                    (formik) => (
                        <Form disabled={loading}>
                            <div className="py-1">
                                <span>ID: </span>
                                <span className="fw-bold ms-1">{sprintf('%04d', editItem.id)}</span>
                            </div>
                            <div className="mb-2">
                                <div className="mb-2">
                                    <label htmlFor="nombre" className="form-label">
                                        Nombre
                                        <span className="text-danger">(*)</span>
                                    </label>
                                    <Field id="nombre" name="nombre" type="text" className="form-control form-control-sm" />
                                    <ErrorMessage name="nombre" component={FormError} />
                                </div>
                            </div>

                            <div className="mb-2">
                                <div className="mb-2">
                                    <label htmlFor="descripcion" className="form-label">
                                        Descripción
                                        <span className="text-danger">(*)</span>
                                    </label>
                                    <Field id="descripcion" as="textarea" name="descripcion" type="text" className="form-control form-control-sm" />
                                    <ErrorMessage name="descripcion" component={FormError} />
                                </div>
                            </div>

                            <div className="mb-2 d-flex justify-content-between">
                                <div>
                                    <span className="text-danger">(*) </span>
                                    <u>Campo obligatorio.</u>
                                </div>
                                <button type="submit" className="btn btn-sm btn-light border border-secondary px-4">
                                    {
                                        (loading) ? (
                                            <Spin size="small" />
                                        ) : "Guardar"
                                    }
                                </button>
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </div>
    );
}

export default FormComponent;